import * as React from "react";
import { CircularProgress, Typography } from "@mui/material";
import "./style.scss";
import { Box } from "@mui/system";
import CircularProgressLabel from "./CircularProgressLabel";

export interface CircularSpinnerProps {
    text?: string;
    showSpinner: boolean;
    hasProgress: boolean;
    progressValue?: number;
    //size is the fontSize in 'em' and size of Spinner in 100px
    size: number;
    statusTextFontSize?: number | string;
    percentageTextFontSize?: number | string;
}

const CircularSpinner = React.memo(
    ({
        text,
        showSpinner,
        hasProgress,
        progressValue,
        size,
        statusTextFontSize = "2em",
        percentageTextFontSize = "2em",
    }: CircularSpinnerProps) => {
        return (
            showSpinner && (
                <div className="spinner-modal">
                    <Box sx={{ position: "relative" }}>
                        {hasProgress ? (
                            <CircularProgressLabel
                                value={progressValue}
                                size={size}
                                percentageTextFontSize={percentageTextFontSize}
                            />
                        ) : (
                            <CircularProgress size={size} />
                        )}
                    </Box>
                    {text && (
                        <Typography
                            variant="caption"
                            component="div"
                            color="text.secondary"
                            fontSize={statusTextFontSize}
                        >
                            {text}
                        </Typography>
                    )}
                </div>
            )
        );
    },
);

export default CircularSpinner;
