export enum SurveySubmitResponseCode {
    EMAIL_VALIDATION_ERROR = "Email validation failed.",
    PHONE_VALIDATION_ERROR = "Phone validation failed.",
}

export enum AutomatedEmailErrorType {
    DEAL_PROPERTIES_NOT_FOUND = "dealPropertiesNotFound",
    HUBSPOT_DEAL_NOT_FOUND = "hubspotDealNotFound",
    MISSING_CUSTOMER_EMAIL = "missingCustomerEmail",
    MISSING_OWNER_ID = "missingOwnerId",
    SPECIALIST_NOT_FOUND = "specialistNotFound",
    MISSING_CUSTOMER_FIRST_NAME = "missingCustomerFirstName",
    MISSING_CUSTOMER_FIRST_AND_LAST_NAME = "missingCustomerFirstAndLastName",
    MISSING_SPECIALIST_FIRST_NAME = "missingSpecialistFirstName",
    MISSING_SPECIALIST_EMAIL = "missingSpecialistEmail",
    MISSING_SPECIALIST_PHONE_NUMBER = "missingSpecialistPhoneNumber",
    MISSING_CREATE_TASK_DATA = "missingCreateTaskData",
    MISSING_DEAL_NEXT_STEPS = "missingDealNextSteps",
    EMAIL_TEMPLATE_NOT_DETERMINED = "emailTemplateNotDetermined",
    MISSING_SECOND_EMAIL_SCHEDULED_TIMESTAMP = "missingSecondEmailScheduledTimestamp",
}

export class AutomatedEmailError extends Error {
    private readonly type: AutomatedEmailErrorType;

    constructor(type: AutomatedEmailErrorType, msg: string) {
        super(msg);

        this.type = type;

        Object.setPrototypeOf(this, AutomatedEmailError.prototype);
    }

    getType(): AutomatedEmailErrorType {
        return this.type;
    }
}
