import { sentryService } from "src/service/sentry";
import { TranslationValue } from "../../context/LocaleContext";
import { get } from "./object";

export const makeTranslator =
    (translations: TranslationValue = {}) =>
    (key: string, fallback?: string) => {
        try {
            return (get(translations, key) ?? fallback ?? key) as string;
        } catch (err) {
            console.error(`An error occured while translating`, err);
            sentryService.report(`An error occured while translating key: ${key}`);
        }

        return "";
    };
