import "./setup";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import "./index.scss";
import * as Sentry from "@sentry/react";
import { routes } from "./routes";
import { createRoot } from "react-dom/client";

const container = document.getElementById("root");

const root = createRoot(container);

root.render(<RouterProvider router={Sentry.wrapCreateBrowserRouter(createBrowserRouter)(routes)} />);
