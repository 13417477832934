import { FinancingPurposeProtocol, ObjectPurposeProtocol } from "src/service/mortgage-engine/types";

export type FeeFields = {
    agencyFee: number;
    estateAgentFee: number;
    purchaseContractCreationFee: number;
    realEstateTransferFee: number;
    propertyRegistrationFee: number;
};

export interface EngineRequestFormFields extends FeeFields {
    financingPurpose: FinancingPurposeProtocol;

    purchasePrice: number;
    earlyRepaymentPenalty?: number;
    propertyValueEstimate: number;
    downPayment: number;
    interestRate: number;

    postalCode?: string;
    remainingMonths: number;
    constructionCosts?: number;
    landPurchasePrice?: number;
    landOwned?: string;
    renovationCosts: number;

    age1: number;
    age2: number;

    income1: number;
    income2: number;

    paycheckCount1: number;
    paycheckCount2: number;

    objectPurpose: ObjectPurposeProtocol;
}

export const defaultEstimateInterestRate = 3.6;

export const buyDefaultFees: FeeFields = {
    agencyFee: 3,
    estateAgentFee: 3.6,
    purchaseContractCreationFee: 2,
    realEstateTransferFee: 3.5,
    propertyRegistrationFee: 1.1,
};

export const refinancingDefaultFees: FeeFields = {
    agencyFee: 2,
    estateAgentFee: 0,
    purchaseContractCreationFee: 0,
    realEstateTransferFee: 0,
    propertyRegistrationFee: 0,
};

export const constructionWithLandPurchaseDefaultFees: FeeFields = {
    agencyFee: 3,
    estateAgentFee: 3.6,
    purchaseContractCreationFee: 2,
    realEstateTransferFee: 3.5,
    propertyRegistrationFee: 1.1,
};

export const constructionWithoutLandPurchaseDefaultFees: FeeFields = {
    agencyFee: 3,
    estateAgentFee: 0,
    purchaseContractCreationFee: 0,
    realEstateTransferFee: 0,
    propertyRegistrationFee: 0,
};

export const defaultInterestRate: number = 3.3;
