import { AxiosRequestConfig } from "axios";
import { User } from "firebase/auth";
import { SESSION_ID_LOCAL_STORAGE_KEY } from "src/shared/constants/token";

const defaultAxiosConfig: AxiosRequestConfig = {
    withCredentials: false,
};
export const buildInternalRequestConfig = async (
    currentUser: User,
    axiosConfig: AxiosRequestConfig = defaultAxiosConfig,
) => {
    const axiosConfigReturn = { ...defaultAxiosConfig, ...axiosConfig };
    if (!axiosConfigReturn.headers) axiosConfigReturn.headers = {};
    if (currentUser) {
        const token = await currentUser.getIdToken();
        axiosConfigReturn.headers["Authorization"] = `Bearer ${token}`;
    } else {
        const sessionId = localStorage.getItem(SESSION_ID_LOCAL_STORAGE_KEY);
        if (sessionId) {
            axiosConfigReturn.headers["Authorization"] = `Bearer ${sessionId}`;
        }
    }
    return axiosConfigReturn;
};

const defaultExternalAxiosConfig: AxiosRequestConfig = {
    withCredentials: false,
};
export const buildExternalRequestConfig = async (
    currentUser: User,
    axiosConfig: AxiosRequestConfig = defaultExternalAxiosConfig,
) => {
    return buildInternalRequestConfig(currentUser, { ...defaultExternalAxiosConfig, ...axiosConfig });
};
