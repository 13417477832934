import {
    DealProperties,
    DealPropertiesKey,
    OmitMappingForAdditionalFields,
    SURVEY_PROPERTIES_TO_DEAL_PROPERTIES_MAP,
    SURVEY_PROPERTIES_TO_HUBSPOT_CONTACT_FIELDS_MAP,
    SurveyPropertiesKey,
} from "../models";

export type HubspotDealPropertyMapRecord = Partial<
    Omit<Record<keyof DealProperties, string>, keyof OmitMappingForAdditionalFields>
>;

/**
 * https://miraclapp.atlassian.net/jira/software/projects/MOR/boards/6?selectedIssue=MOR-1407
 * Starting from this ticket, we want to reduce the amout of synced properties to Hubspot to a minimum
 *
 * We still sync only the properties that are necessary for some Hubspot analysis and those are:
 *
 * Bearbeitungsgebühr (sum, percentage)
 * Bank won / lost
 * Bruttokreditbetrag
 * LTV (Engine)
 * DSTI (Engine)
 * Milestones
 * Contact details (phone number, email, name)
 * Kaufvertragsservice
 * Ablebensversicherung
 * Hub link
 */
export const ALWAYS_SYNCABLE_HUBSPOT_DEAL_PROPERTIES_MAP: HubspotDealPropertyMapRecord = {
    agencyRevenue: "agency_revenue",
    agencyFee: "bearbeitungsgebuhr",
    agencyCosts: "bearbeitungsgebuhr_summe",
    bankWon1: "bank",
    rejectedBank1: "bank_1",
    rejectedBank2: "bank_2",
    rejectedBank3: "bank_3",
    bankRejectionDate1: "date_of_rejection_1",
    bankRejectionDate2: "date_of_rejection_2",
    bankRejectionDate3: "date_of_rejection_3",
    grossLoanAmount: "brutto_kreditbetrag",
    ltv: "ltv__engine_",
    dsti: "dsti__engine_",
    emailBorrower1: "email_kn1",
    firstNameBorrower1: "first_name",
    lastNameBorrower1: "last_name",
    phoneNumberBorrower1: "handynummer_kn1",
    salutationBorrower1: "anrede",
    requestLifeInsurance: "request_life_insurance",
    hubMagicLink: "hub_magic_link",
    submissionCount: "submission_count",
    createDate: "createdate",
    ownerId: "hubspot_owner_id",
    closedLostReason: "closed_lost_reason",
    surveySubmitWithoutPhoneNumber: "survey_submit_without_phone_number",
    closedLostReasonSingle: "closed_lost_reason_single",
    closedLostReasonOther: "closed_lost_reason_other",
    ce1: "ce1",
    dealStage: "dealstage",

    esisOptionBankName: "esis_option_bank_name",
    esisOptionNominalInterestRate: "esis_option_nominal_interest_rate",
    esisOptionEffectiveInterestRate: "esis_option_effective_interest_rate",
    esisOptionFixedDuration: "esis_option_fixed_duration",
    esisOptionOverallDuration: "esis_option_overall_duration",
    esisOptionMonthlyRate: "esis_option_monthly_rate",
    esisOptionPayoutAmount: "esis_option_payout_amount",
    esisOptionGrossLoanAmount: "esis_option_gross_loan_amount",
    esisOptionAgencyFee: "esis_option_agency_fee",

    // the only milestone that we need a mapping for as the others are updated directly
    customerAppointmentBooking: "customer_appointment_booking",
    requiredCustomerDocuments: "required_customer_documents",
    requiredCustomerDocumentsDetails: "required_customer_documents_details",
    requiredCustomerDocumentsExportDifference: "required_documents_difference",
    userSource: "user_source",
    userSourceVersion: "user_source_version",
    customerReached: "customer_reached",
    potentialHomeowner: "potential_homeowner",
    propertyExposePhoneNumber: "makler__telefonnummer",
};

export const ALL_HUBSPOT_DEAL_PROPERTIES_MAP: HubspotDealPropertyMapRecord = {
    // read-only fields
    ...ALWAYS_SYNCABLE_HUBSPOT_DEAL_PROPERTIES_MAP,

    // General deal hubspot keys
    name: "dealname",
    additionalInfoMagicLink: "additional_info_magic_link",
    realEstateAgent: "tippgeber",

    // Financing object keys
    propertyType: "art_der_immobilie_",
    propertyFinancingType: "art_der_finanzierung",
    propertyCountry: "land_immobilie",
    propertyState: "bundesland_immobilie",
    propertyZipCode: "plz",
    propertyCity: "ort_immobilie",
    propertyAddress: "adresse",
    propertyConstructionYear: "baujahr",
    propertyLastRenovatedYear: "letzte_renovierung",
    propertyLivingSpaceArea: "wohnflache",
    propertyLandArea: "haus_grundstucksflache",
    propertyCompleted: "fertiggestellt",

    // Personal hubspot keys

    emailBorrower2: "email_kn2",
    firstNameBorrower2: "first_name_kn2",
    phoneNumberBorrower2: "handynummer_kn2",
    lastNameBorrower2: "last_name_kn2",

    // Job hubspot keys
    monthlyNetIncomeBorrower1: "nettogehalt",
    numberOfSalariesBorrower1: "anzahl_der_gehalter_kn1",

    monthlyNetIncomeBorrower2: "nettogehalt_kn2",
    numberOfSalariesBorrower2: "anzahl_der_gehalter_kn2",

    monthlyNetIncomeBorrower3: "nettogehalt_kn3",
    numberOfSalariesBorrower3: "anzahl_der_gehalter_kn3",

    // Project costs hubspot keys
    purchasePrice: "kaufpreis",
    remainingMonths: "restlaufzeit_alter_kredite",
    realEstateTransferFee: "grunderwerbssteuer",
    realEstateTransferFeeCosts: "grunderwerbssteuer_summe",
    estateAgentFee: "vermittlungsgebuhr_immobilientreuhander",
    estateAgentFeeCosts: "vermittlungsgebuhr_immobilientreuhander_summe",
    propertyRegistrationFee: "eintragungsgebuhr_eigentumsrecht",
    propertyRegistrationFeeCosts: "eintragungsgebuhr_eigentumsrecht_summe",
    purchaseContractCreationFee: "kaufvertragserrichtungsgebuhr_inkl__treuhandschaft",
    purchaseContractCreationFeeCosts: "kaufvertragserrichtungsgebuhr_inkl__treuhandschaft_summe",
    additionalCosts: "summe_kaufnebenkosten",
    purchasePriceWithAdditionalCosts: "gesamter_kaufpreis__inkl__nebenkosten_",
    totalConstructionCosts: "gesamtsumme_baukosten",
    downPayment: "eigenkapital",
    payoutAmount: "auszahlungsbetrag",
    projectCosts: "projektkosten",

    // Finacning costs hubspot keys
    landRegistryEntryFee: "grundbucheintragungsgebuhr",
    landRegistryEntryFeeCosts: "grundbucheintragungsgebuhr_summe",
    landRegistryApplicationEntryCosts: "grundbuchsgesuch_eingabegebuhren",
    notaryCosts: "beglaubigungskosten",
    appraisalCosts: "gebuhr_liegenschaftsbewertung",
    oneTimeAdditionalCreditCosts: "kreditnebengebuhren_einmalig",

    // Referral hubspot keys
    referral: "referral_source",
    referralSourceName: "referral_source_name",

    dti: "dti__engine_",
    ltvCalculated: "ltv",
    dstiCalculated: "dsti",
    loanAmount: "kreditbetrag",
    currentInterestRate: "umschuldung_aktueller_nominalzins",
    ltvSurvey: "ltv__survey_",
    dstiSurvey: "dsti__survey_",
    surveyCooperativeApartment: "surveycooperativeapartment",
    surveyRealEstateOwned: "immobilieneigentum_in_der_familie",
    // export info
    lastBankExportTime: "last_bank_export_time",

    // Additional survey related data
    contractCreationService: "vertragserrichtung_service",
    contractorFreelySelectable: "vertragserrichter_wahlbar",
    surveyIntention: "kaufvorhaben__survey_",
    surveyUsage: "art_der_immobiliennutzung__survey_",
    surveyFinancingType: "survey_art_der_finanzierung",
    contactPerson: "kontaktperson",
    customerIntent: "customer_intent",
    oldCustomerIntent: "old_customer_intent",
    needOfCustomerSurvey: "need_of_customer",
    surveyHouseBankFinancingPreference: "surveyhousebankfinancingpreference",
    surveyUntilWhenFinancingCompleted: "bis_wann_finanzierung_stehen",
    surveyRoughConstructionStartDate: "ungefahrer_baubeginntermin",
    surveyGoalOfRefinancing: "user_comment",
    surveyPropertyAlreadyVisited: "immobilie_schon_besichtigt",
    surveyPropertyAlreadyFound: "immobilie_gefunden_survey",
    surveyHowCanWeHelpYou: "wie_konnen_wir_dir_helfen__survey",
    surveyHowCanWeHelpyouOther: "wie_konnen_wir_dir_helfen___sonstige__survey",
    surveyPropertyAlreadyDecided: "surveypropertyalreadydecided",
    talkedToLendingBank: "umschuldung_mit_kreditgebenden_bank_gesprochen",
    numberOfBorrowers: "anzahl_kn",
    surveyRefinancingDebtLeftAmount: "restschuld_hypothekardarlehen",
    surveyRefinancingDebtRemainingYears: "umschuldung_restlaufzeit",
    surveyConstructionIncludesLandPurchase: "grundstuck_mitfinanzieren",
    surveyLandPurchasePrice: "kaufpreis_grundstuck",
    surveyVersion: "survey_version",
    surveySubmissionPlanReady: "einreichplan_fertig",
    surveyConstructionCostsAlreadyAvailable: "baukostenaufstellung_schon_vorhanden",
    surveyConstructionEstimateRecieved: "kostenvoranschlage_schon_eingeholt",
    surveyWhenWasPropertyBought: "umschuldung_wann_gekauft",
    surveyCurrentMonthlyRate: "umschuldung_aktuelle_rate",
    surveyCurrentCreditBank: "umschuldung_kreditgebende_bank",
    surveyPropertyValueEstimate: "schatzwert_der_immobilie",
    surveyPurchaseProgress: "finanzierungsprozess_survey",
    surveyWhichBanksAreHouseBanks: "hausbanken_survey",
    surveyWhichBanksAreHouseBanksOther: "hausbanken_sonstige_survey",
    surveyHowSoonFinancingCommitmentNeeded: "wie_schnell_finanzierungszusage_gebraucht_survey",
    propertyConstructionCosts: "baukosten",
    surveyAdditionalInvestmentCosts: "zusatzliche_investionskosten_survey",
    surveyValueOfExistingProperty: "wert_der_zusatzliche_immobilie_survey",
    surveyExistingPropertyZipcode: "plz_bestandsobjekt",
    surveyOriginalPurchaseOrConstructionPrice: "ursprunglicher_kaufpreis_bzw__baukosten",
    surveyExistingPropertyOutstandingLoanAmount: "zusatzliche_immobilie_aushaftende_kredit_survey",
    surveyNextStepsInPurchaseProcess: "nachsten_schritte_im_kaufprozess_survey",
    surveyNextStepsInPurchaseProcessOther: "nachsten_schritte_im_kaufprozess_sonstige_survey",
    surveyPurchasePlannedAt: "bis_wann_soll_der_kauf_stattfinden__survey",
    user_id: "user_id",
    hs_google_click_id: "gclid",
    hs_facebook_click_id: "fbclid",
    utm_campaign: "utm_campaign",
    utm_content: "utm_content",
    utm_medium: "utm_medium",
    utm_source: "utm_source",
    utm_term: "utm_term",
    companyId: "company_id",
};

// ones that will always be omitted as they can't be updated on Hubspot and/or can cause errors
export const OMITTED_CALCULATED_SYNC_DEAL_PROPERTIES_FIELDS: (keyof DealProperties)[] = [
    "dstiCalculated",
    "ltvCalculated",
    "propertyValueEstimate",
];

export const OMITTED_COMPLETE_SYNC_DEAL_PROPERTIES_FIELDS: (keyof DealProperties)[] = [
    ...OMITTED_CALCULATED_SYNC_DEAL_PROPERTIES_FIELDS,
    "hubMagicLink",
];

export const OMITTED_PARTIAL_SYNC_DEAL_PROPERTIES_FIELDS: (keyof DealProperties)[] = [
    ...OMITTED_COMPLETE_SYNC_DEAL_PROPERTIES_FIELDS,
    "ownerId",
    "dealStage",
];

export const DEAL_PROPERTIES_TO_HUBSPOT_CONTACT_MAP: Partial<Record<DealPropertiesKey, string>> = Object.entries(
    SURVEY_PROPERTIES_TO_HUBSPOT_CONTACT_FIELDS_MAP,
).reduce((acc, curr) => {
    const [surveyKey, hubspotContactKey] = curr as [SurveyPropertiesKey, string];
    return {
        ...acc,
        [SURVEY_PROPERTIES_TO_DEAL_PROPERTIES_MAP[surveyKey] as string]: hubspotContactKey,
    };
}, {});
