import { Button, Grid, Modal, Typography } from "@mui/material";
import React, { FC, useCallback, useContext, useState } from "react";
import { toast } from "react-toastify";
import { defaultToastProps, defaultModalProps, defaultNotificationModalState } from "./constants";
import {
    NotificationModalProps,
    NotificationModalState,
    NotificationSeverityLevel,
    NotificationToastProps,
    NotificationType,
} from "./types";

type NotificationContextData = {
    notify: <T extends NotificationType>(
        type: T,
        level: NotificationSeverityLevel,
        props?: T extends "toast" ? NotificationToastProps : NotificationModalProps,
    ) => void;
};

const NotificationContext = React.createContext<NotificationContextData>(undefined);

export const NotificationContextProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
    const [notificationModalState, setNotificationModalState] =
        useState<NotificationModalState>(defaultNotificationModalState);

    const handleToast = useCallback(
        (level: NotificationSeverityLevel, props: NotificationToastProps = defaultToastProps) => {
            toast(props?.message, { type: level, autoClose: props?.autoClose });
        },
        [],
    );

    const handleModal = useCallback(
        (level: NotificationSeverityLevel, props: NotificationModalProps = defaultModalProps) => {
            setNotificationModalState({
                visible: true,
                level,
                ...props,
                button: { ...props?.button },
            });
        },
        [],
    );

    const handleCloseModal = useCallback(() => {
        notificationModalState?.button?.onClick?.();
        setNotificationModalState({
            ...defaultNotificationModalState,
            button: { ...defaultNotificationModalState.button },
        });
    }, [notificationModalState?.button]);

    const notify = useCallback(
        <T extends NotificationType>(
            type: T,
            level: NotificationSeverityLevel,
            props?: T extends "toast" ? NotificationToastProps : NotificationModalProps,
        ) => {
            switch (type) {
                case "toast":
                    handleToast(level, props as NotificationToastProps);
                    break;
                case "modal":
                    handleModal(level, props as NotificationModalProps);
                    break;
                default:
                    throw new Error("Notification type invalid");
            }
        },
        [handleModal, handleToast],
    );

    return (
        <NotificationContext.Provider value={{ notify }}>
            {children}
            <Modal
                open={notificationModalState.visible}
                onClose={handleCloseModal}
                sx={{ alignItems: "center", justifyContent: "center", display: "flex" }}
            >
                <Grid
                    container
                    flexDirection="column"
                    sx={{ borderRadius: 2, padding: 2, backgroundColor: "white", maxWidth: 500 }}
                >
                    <Grid item padding={1} marginBottom={2}>
                        <Typography variant="h4">{notificationModalState.title}</Typography>
                    </Grid>
                    <Grid item padding={1} marginBottom={3}>
                        {notificationModalState.body}
                    </Grid>
                    <Grid container item justifyContent="right">
                        <Button
                            variant="text"
                            size="small"
                            onClick={handleCloseModal}
                            color={notificationModalState.level}
                        >
                            {notificationModalState?.button?.label}
                        </Button>
                    </Grid>
                </Grid>
            </Modal>
        </NotificationContext.Provider>
    );
};

export const useNotificationContext = () => useContext(NotificationContext);
