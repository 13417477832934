import { TrueFalseValue } from "@miraclapp/mortgaging-shared";
import AbTestingServiceInstance from "src/service/ab-test";
import { buyDefaultFees } from "src/shared/types/engine";
import { SurveyFormData } from "src/shared/types/survey";

export const SURVEY_COOKIE_USER_ID = "user_id";
export const SURVEY_COOKIE_GCLID = "gclid";
export const SURVEY_UPDATE_CONTACT_RETRY_ATTEMPT_COUNT = 3;
export const SURVEY_UPDATE_CONTACT_RETRY_TIMEOUT = 5000;

export const DEFAULT_SURVEY_FORM_VALUES: Partial<SurveyFormData> = {
    estateAgentFee: buyDefaultFees.estateAgentFee,
    realEstateTransferFee: buyDefaultFees.realEstateTransferFee,
    propertyRegistrationFee: buyDefaultFees.propertyRegistrationFee,
    purchaseContractCreationFee: buyDefaultFees.purchaseContractCreationFee,
    surveyVersion: AbTestingServiceInstance.getVersionedVariant(),
    consentedMarketingEmails: TrueFalseValue.FALSE,
};

export const DEFAULT_SPECIALIST_APPOINTMENT_EMAILS = ["ivana.krsmanovic@miracl.at", "david.savasci@miracl.at"];

export const SURVEY_ESTIMATE_STORAGE_KEY = "surveyEstimate";
