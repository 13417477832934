import { AxiosInstance } from "axios";
import axiosRetry from "axios-retry";

export function withRetry(instance: AxiosInstance) {
    axiosRetry(instance, {
        retries: 3,
        retryDelay: (retryCount) => retryCount * 2000,
        onRetry: (retryCount, error, requestConfig) => {
            console.log(`(${retryCount}) - Retrying ${requestConfig.url} ...`);
        },
    });

    return instance;
}
