import { config } from "src/config";

export function getCookieOptions() {
    return {
        sameSite: "none" as const,
        path: "/",
        secure: true,
        domain: config.environment !== "development" ? ".miracl.at" : "localhost",
    };
}
