import { SurveyCustomerIntentType } from "@miraclapp/mortgaging-shared";
import { create } from "zustand";
import { persist, devtools, createJSONStorage } from "zustand/middleware";

type SurveyEventStateData = {
    intention: string | null;
    intentType: SurveyCustomerIntentType;
};

interface SurveyEventState {
    data: SurveyEventStateData;
    update: (payload: any) => void;
}

// https://github.com/pmndrs/zustand
export const useSurveyEventStore = create<SurveyEventState>()(
    devtools(
        persist(
            (set) => ({
                data: {
                    intention: null,
                    intentType: null,
                },
                update: (payload: Partial<SurveyEventStateData>) => {
                    return set((prevState) => ({
                        ...prevState,
                        data: {
                            ...prevState.data,
                            ...payload,
                        },
                    }));
                },
            }),
            {
                name: "ga-data",
                storage: createJSONStorage(() => sessionStorage),
            },
        ),
    ),
);
