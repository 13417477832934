import { Box, useMediaQuery, useTheme } from "@mui/material";
import Snowfall from "react-snowfall";
import { ChristmasLights } from "src/ui/components/custom/ChristmasLights/ChristmasLights";

interface ApplicationWrapperProps {
    children: React.ReactNode;
    displaySnow: boolean;
    displayChristmasLights: boolean;
}

export const ApplicationWrapper = ({ children, displaySnow, displayChristmasLights }: ApplicationWrapperProps) => {
    const theme = useTheme();
    const mobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
    return (
        <Box
            sx={{
                height: "100%",
                display: "flex",
                flexDirection: mobileScreen ? "column" : "row",
            }}
        >
            {children}
            {displaySnow && <Snowfall />}
            {displayChristmasLights && <ChristmasLights />}
        </Box>
    );
};
