import { QueryParams } from "src/shared/types/route";

export const buildQueryString = (queryParams: QueryParams = {}): string => {
    if (!Object.keys(queryParams).length) return "";

    return (
        "?" +
        (Object.entries(queryParams)
            .map(([key, value]) => `${key}=${value}`)
            .join("&") ?? "")
    );
};

export const getSearchParams = (): URLSearchParams => {
    return new URLSearchParams(window.location.search);
};
