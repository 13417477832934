import { User } from "firebase/auth";
import { config } from "../../config";
import { axiosApiInstance } from "../api/axiosApiInstance";
import { buildInternalRequestConfig } from "../api/helpers";

const base = `${config.server.url}`;

export const getVersion = async (currentUser: User): Promise<string> => {
    const config = await buildInternalRequestConfig(currentUser);
    const response = await axiosApiInstance.get<string>(`${base}/version`, config);
    return response.data;
};
