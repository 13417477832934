import { DEAL_MULTI_VALUE_SEPARATOR } from "../../constants";
import { DealProperties } from "../../models";
import * as Yup from "yup";

export const numberValidation = (min?: number, max?: number, required = false) => {
    let emptyVal = false;
    let numberTransform = Yup.number().transform((value) => {
        const num = Number(value);
        if (isNaN(num)) {
            emptyVal = true;
            return 0;
        }
        return num;
    });
    let validation = required ? numberTransform.required() : numberTransform;
    if (!emptyVal && min != null && min != undefined) {
        validation = validation.min(min);
    }
    if (!emptyVal && max != null && max != undefined) {
        validation = validation.max(max);
    }
    return validation;
};

export const stringValidation = (required = false) => {
    return required ? Yup.string().required() : Yup.string().notRequired();
};

export const stringOptionValidation = (oneOf: string[], required = false) => {
    let validation = stringValidation(required);
    if (oneOf) {
        validation = validation.oneOf(oneOf);
    }
    return validation;
};

export const multiSelectValidation = (oneOf: string[], required = false) => {
    let validation = Yup.string().test("each value one of", "${path} has invalid options", (value, context) => {
        if (value) {
            return value?.split(DEAL_MULTI_VALUE_SEPARATOR)?.every((option) => oneOf.includes(option));
        }
        // if it is required, then we return false, if it is not, we return true for this part of the validation
        return !required;
    });
    return required ? validation.required() : validation.notRequired();
};

export const validateMultipleSchemas = async (
    value: DealProperties,
    schemas: Yup.ObjectSchema<any>[],
    limit?: number,
) => {
    const schemaLimit = limit && limit >= 0 && limit < schemas.length ? limit : schemas.length;
    for (let i = 0; i < schemaLimit; i++) {
        await schemas[i].validate(value);
    }
};
