import { ReactNode } from "react";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import qs from "query-string";

type QueryParamsProviderProps = {
    children: ReactNode;
};

export function QueryParamsProvider({ children }: QueryParamsProviderProps) {
    return (
        <QueryParamProvider
            adapter={ReactRouter6Adapter}
            options={{
                searchStringToObject: qs.parse,
                objectToSearchString: qs.stringify,
            }}
        >
            {children}
        </QueryParamProvider>
    );
}
