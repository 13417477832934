import { Translator } from "src/context/LocaleContext";
import { round } from "src/utils/common";
import { MonthsDurationStyle } from "../hooks/use-months-duration-style";

export const formatNumberByLocale = (
    value: number,
    language: string,
    decimals = 2,
    style: "regular" | "percent" | "k" = "regular",
    defaultValue: string = undefined,
): string => {
    try {
        if (value === undefined) throw new Error("Undefined value");

        const formatted = new Intl.NumberFormat(language, {
            minimumFractionDigits: decimals,
            maximumFractionDigits: decimals,
        }).format(value);

        switch (style) {
            case "regular":
                return formatted;
            case "percent":
                return `${formatted} %`;
            case "k":
                return `${(value / 1000).toFixed(0)}k`;
        }
    } catch (error) {
        return defaultValue;
    }
};

export const setDateToUTCMidnight = (date: Date): Date => {
    const midnightDate = new Date(date);
    midnightDate.setHours(0, 0, 0, 0);

    return midnightDate;
};

// By default returns current midnight date
export const setDateToUTCMidnightForSync = (date: Date = new Date()): Date => {
    const midnightDate = new Date(date);
    midnightDate.setUTCHours(0, 0, 0, 0);

    return midnightDate;
};

export const parseUTCMidnightDateTime = (date: string): number => {
    try {
        const midnightDate = setDateToUTCMidnight(new Date(Date.parse(date)));
        return Date.UTC(
            midnightDate.getFullYear(),
            midnightDate.getMonth(),
            midnightDate.getDate(),
            midnightDate.getHours(),
        );
    } catch (error) {
        return 0;
    }
};

export const SafeNumber = (value: any, defaultValue = 0): number => {
    const safeValue = Number(value);
    return isNaN(safeValue) ? defaultValue : safeValue;
};

export const SafeRound = (value: any, decimals = 2): number => {
    return round(SafeNumber(value), decimals);
};

type FormatMonthsDurationOptions = {
    months: number;
    style: MonthsDurationStyle;
    t: Translator;
};

export function formatMonthsDuration({ months, t, style }: FormatMonthsDurationOptions) {
    if (!months) return "-";

    const parts = [];
    const years = Math.floor(months / 12);
    const remainingMonths = months % 12;

    // Set lg style if only one of the options should be displayed since it can always fit
    const yearsStyle = remainingMonths > 0 ? style : "lg";
    const monthsStyle = years > 0 ? style : "lg";

    if (years > 0) {
        const form = getSingularOrPluralKey({ name: "years", count: years });
        parts.push(t(`common.monthsDuration.${form}.${yearsStyle}`));
    }

    if (remainingMonths > 0) {
        const form = getSingularOrPluralKey({ name: "months", count: remainingMonths });
        parts.push(t(`common.monthsDuration.${form}.${monthsStyle}`));
    }

    return parts
        .join(t(`common.monthsDuration.separator.${style}`))
        .replace("{{years}}", `${years}`)
        .replace("{{months}}", `${remainingMonths}`);
}

function getSingularOrPluralKey({ name, count }: { name: string; count: number }) {
    const form = count === 1 ? "singular" : "plural";
    return `${name}_${form}`;
}
