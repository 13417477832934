import { NotificationModalProps, NotificationModalState, NotificationToastProps } from "./types";

export const defaultToastProps: NotificationToastProps = {
    message: "Toast!",
    autoClose: 2500,
};

export const defaultModalProps: NotificationModalProps = {
    title: "Modal",
    body: undefined,
    button: { label: "Close", onClick: undefined },
};

export const defaultNotificationModalState: NotificationModalState = {
    level: "info",
    visible: false,
    ...defaultModalProps,
    button: { ...defaultModalProps.button },
};
