import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const Wrapper = styled(Box)({
    height: "100%",
    flexGrow: 1,
    display: "flex",
});

export const Column = styled(Box)({
    width: "50%",
    height: "100%",
    flexGrow: 1,
    padding: "32px",
    display: "flex",
    flexDirection: "column",
    gap: 8,
});

export const Row = styled(Box)({
    width: "500px",
    margin: "0 auto",
});

export const LogoWrapper = styled(Box)({
    width: "200px",
    display: "flex",
    flexDirection: "column",
});
