import * as Yup from "yup";
import { DealProperties } from "../../../models";
import { Shape } from "../types";
import { numberValidation } from "../util";

export const projectCostsSchema = Yup.object<Shape<Partial<DealProperties>>>({
    purchasePrice: numberValidation(0),
    remainingMonths: numberValidation(0),
    propertyValueEstimate: numberValidation(0),
    realEstateTransferFee: numberValidation(0),
    estateAgentFee: numberValidation(0),
    purchaseContractCreationFee: numberValidation(0),
    propertyRegistrationFee: numberValidation(0),
    realEstateTransferFeeCosts: numberValidation(0),
    estateAgentFeeCosts: numberValidation(0),
    purchaseContractCreationFeeCosts: numberValidation(0),
    propertyRegistrationFeeCosts: numberValidation(0),
    purchasePriceWithAdditionalCosts: numberValidation(0),
    totalConstructionCosts: numberValidation(0),
    downPayment: numberValidation(0),
    payoutAmount: numberValidation(0),
    projectCosts: numberValidation(0),
});
