import * as Yup from "yup";
import {
    AUSTRIA_STATE_VALUES,
    EMPLOYMENT_TYPE_VALUES,
    MARITAL_STATUS_VALUES,
    TRUE_FALSE_VALUES,
    BORROWER_INCOME_CURRENCY_OPTIONS,
    OLD_SELF_EMPLOYED_TYPE_VALUE,
} from "../../../constants";
import { Shape } from "../types";
import { numberValidation, stringOptionValidation, stringValidation } from "../util";
import { BorrowerEnumerator, DealProperties } from "../../../models";

const generateBorrowerSchemaByIndex = (borrowerEnumerator: BorrowerEnumerator) => {
    return Yup.object<Shape<Partial<DealProperties>>>({
        [`email${borrowerEnumerator}`]: stringValidation(),
        [`firstName${borrowerEnumerator}`]: stringValidation(),
        [`lastName${borrowerEnumerator}`]: stringValidation(),
        [`maritalStatus${borrowerEnumerator}`]: stringOptionValidation(MARITAL_STATUS_VALUES),
        [`birthday${borrowerEnumerator}`]: numberValidation(),
        [`education${borrowerEnumerator}`]: stringValidation(),
        [`numberOfCars${borrowerEnumerator}`]: numberValidation(0),
        // we accept Selbstständig but we also used to accept "Selbständig" (backward compatible)
        [`employmentType${borrowerEnumerator}`]: stringOptionValidation([
            ...EMPLOYMENT_TYPE_VALUES,
            OLD_SELF_EMPLOYED_TYPE_VALUE,
        ]),
        [`employedSince${borrowerEnumerator}`]: numberValidation(),
        [`employer${borrowerEnumerator}`]: stringValidation(),
        [`employmentPosition${borrowerEnumerator}`]: stringValidation(),
        [`employmentBranch${borrowerEnumerator}`]: stringValidation(),
        [`salaryCurrency${borrowerEnumerator}`]: stringOptionValidation(BORROWER_INCOME_CURRENCY_OPTIONS),
        [`changeOfEmployerPlanned${borrowerEnumerator}`]: stringOptionValidation(TRUE_FALSE_VALUES),
        [`phoneNumber${borrowerEnumerator}`]: stringValidation(),
        [`state${borrowerEnumerator}`]: stringOptionValidation(AUSTRIA_STATE_VALUES),
        [`residenceInAustriaSince${borrowerEnumerator}`]: numberValidation(),
        [`homeAddress${borrowerEnumerator}`]: stringValidation(),
        [`homeZipCode${borrowerEnumerator}`]: numberValidation(),
        [`homeCity${borrowerEnumerator}`]: stringValidation(),
        [`homeCountry${borrowerEnumerator}`]: stringValidation(),
        [`title${borrowerEnumerator}`]: stringValidation(),
        [`salutation${borrowerEnumerator}`]: stringValidation(),
        [`age${borrowerEnumerator}`]: numberValidation(0),
    });
};

export const borrower1Schema = generateBorrowerSchemaByIndex("Borrower1");
export const borrower2Schema = generateBorrowerSchemaByIndex("Borrower2");
export const borrower3Schema = generateBorrowerSchemaByIndex("Borrower3");
export const borrower4Schema = generateBorrowerSchemaByIndex("Borrower4");

export const borrowerSchemas = [borrower1Schema, borrower2Schema, borrower3Schema, borrower4Schema];
