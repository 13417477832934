export enum ApplicationRoute {
    HOME = "/",
    LOGIN = "/login",
    SIGNUP = "/signup",
    NEXT_STEPS = "/next-steps",
    CONSULTATION = "/consultation",
    CALCULATOR = "/calculator",
    DOCUMENTS = "/documents",
    DEAL = "/deal",
    SUBMISSION = "/submission",
    LOADER = "/loader",
    SETTINGS = "/settings",
    SURVEY = "/survey",
    ADDITIONAL_INFO = "/additional-info",
    HUB = "/hub",
    SCHEDULER = "/scheduler",
    TRASH = "/trash",
    HOUSE_BANK_OFFER = "/house-bank-offer",
    COMPANIES = "/companies",
    USERS = "/users",
    CUSTOMER_DATA = "/customer-data",
    CUSTOMER_DATA_BANKS = "/customer-data/banks",
    NOT_FOUND = "/404",
    ERROR = "/error",

    // FOR DEVELOPMENT PURPOSES
    DEV = "/dev",
}

export type QueryParams = Record<string, string>;
