import Color from "color";

export function generateColorScheme(primaryColor = "#8080ff") {
    const baseColor = Color(primaryColor);
    const adjustedBaseColor = baseColor.isLight() ? baseColor.darken(0.2) : baseColor.lighten(0.2);

    return {
        400: adjustedBaseColor.lighten(0.5).hex(),
        500: baseColor.hex(),
        600: adjustedBaseColor.darken(0.5).hex(),
    };
}
