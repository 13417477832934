import { Typography } from "@mui/material";
import { Role } from "@miraclapp/mortgaging-shared";
import { useCallback, useContext, useEffect } from "react";
import { AuthContext } from "src/context/AuthContext";
import { useTranslation } from "src/context/LocaleContext";
import { useNotificationContext } from "src/context/notification/NotificationContext";
import { sentryService } from "src/service/sentry";
import { getVersion } from "src/service/version/api";
import packageInfo from "../../../package.json";

const IGNORE_CHECK_VERSION_ROLES = [Role.CUSTOMER, Role.SURVEY_USER];

//30 minutes is enough
const CHECK_VERSION_INTERVAL = 1_800_000;
export const useCheckVersion = () => {
    const { applicationUser, rolePrivileges } = useContext(AuthContext);
    const { notify } = useNotificationContext();
    const { t } = useTranslation();

    const checkVersion = useCallback(async () => {
        if (!applicationUser || !rolePrivileges.role || IGNORE_CHECK_VERSION_ROLES.includes(rolePrivileges.role)) {
            return;
        }

        const backendVersion = await getVersion(applicationUser);

        if (backendVersion === packageInfo.version) return;

        console.log(backendVersion, packageInfo.version);

        notify("modal", "info", {
            title: t("common.version"),
            body: <Typography textAlign="justify">{t("common.invalidVersion")}</Typography>,
            button: { label: t("common.cancel") },
        });

        sentryService.setContext("version", {
            frontend: packageInfo.version,
            backend: backendVersion,
        });

        sentryService.report("Mismatched versions of applications");
    }, [applicationUser, notify, rolePrivileges.role, t]);

    useEffect(() => {
        checkVersion();

        const interval = setInterval(async () => {
            checkVersion();
        }, CHECK_VERSION_INTERVAL);

        return () => clearInterval(interval);
    }, [checkVersion]);
};
