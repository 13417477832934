import { Fragment, ReactNode } from "react";
import { useInitializeApp } from "src/shared/hooks/useInitializeApp";

type InitializationProviderProps = {
    children: ReactNode;
};

export const InitializationProvider = ({ children }: InitializationProviderProps) => {
    useInitializeApp();

    return <Fragment>{children}</Fragment>;
};
