import { Box, CircularProgress, CircularProgressProps, Typography } from "@mui/material";
import * as React from "react";

export interface CircularProgressLabelProps extends CircularProgressProps {
    value: number;
    size: number;
    percentageTextFontSize: string | number;
}

const CircularProgressLabel = ({
    value,
    size,
    percentageTextFontSize,
}: CircularProgressLabelProps): React.ReactElement | null => {
    return (
        <Box sx={{ position: "relative", display: "inline-flex" }}>
            <CircularProgress size={size} variant="determinate" value={value} />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: "absolute",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <Typography
                    variant="caption"
                    component="div"
                    color="text.secondary"
                    fontSize={percentageTextFontSize}
                >{`${Math.round(value)}%`}</Typography>
            </Box>
        </Box>
    );
};
export default CircularProgressLabel;
