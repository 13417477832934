import { storageService } from "../storage";
import packageInfo from "../../../package.json";
import { config } from "src/config";
import { getSearchParams } from "src/utils/route";
import { AppVariant } from "@miraclapp/mortgaging-shared";

export type AbTestingServiceConfig = {
    variantRatio: number;
    storageKey: string;
    version: string;
};

class AbTestingService {
    private variant: AppVariant;
    private version: string;
    private availableVariants: AppVariant[] = [AppVariant.ORIGIN, AppVariant.VARIANT];

    constructor(config: AbTestingServiceConfig) {
        this.assertConfig(config);

        this.version = config.version;

        // First we check if the variant is present in the query params
        const queryValue = getSearchParams().get("ab") as AppVariant;

        if (queryValue && this.variantValueValid(queryValue)) {
            this.variant = queryValue;
            return;
        }

        // If not, then we check if it is stored in local storage
        const storedValue = storageService.get(config.storageKey) as AppVariant;

        if (storedValue && this.variantValueValid(storedValue)) {
            this.variant = storedValue;
            return;
        }

        // If that's not the case, we generate and store a new value
        this.variant = this.generateVariant(config.variantRatio);

        storageService.set(config.storageKey, this.variant);
    }

    private assertConfig(config: AbTestingServiceConfig) {
        if (config.variantRatio < 0 || config.variantRatio > 1) {
            throw new Error("[ab-test constructor error] Variant ratio value out of valid range [0, 1]");
        }

        if (!config.storageKey) {
            throw new Error("[ab-test constructor error] Provide local storage key in config.");
        }

        if (!config.version) {
            throw new Error("[ab-test constructor error] Provide app version in config.");
        }
    }

    private variantValueValid(value: AppVariant): boolean {
        return this.availableVariants.includes(value);
    }

    private generateVariant(variantRatio: number): AppVariant {
        return Math.random() < variantRatio ? AppVariant.VARIANT : AppVariant.ORIGIN;
    }

    public getVariant(): AppVariant {
        return this.variant;
    }

    public isVariant(): boolean {
        return this.variant === "variant";
    }

    public getVersionedVariant() {
        return `${this.version}-${this.variant}`;
    }
}

const AbTestingServiceInstance = new AbTestingService({
    variantRatio: config.survey.abTestVariantRatio,
    storageKey: config.survey.storageKey,
    version: packageInfo.version,
});

export default AbTestingServiceInstance;
