import { DealPropertyFinancingType, SurveyCustomerIntentType, SurveyProperties } from "../../../models";

import {
    SURVEY_DEVELOPMENT_TEST_DATA_HIT_COOPERATIVE_APARTMENT,
    SURVEY_DEVELOPMENT_TEST_DATA_HIT_COOPERATIVE_CONTRIBUTION,
    SURVEY_DEVELOPMENT_TEST_DATA_HIT_FORECAST_COOPERATIVE_APARTMENT,
    SURVEY_DEVELOPMENT_TEST_DATA_HIT_FORECAST_COOPERATIVE_CONTRIBUTION,
    SURVEY_DEVELOPMENT_TEST_DATA_HIT_FORECAST_INTERIM_FINANCING,
    SURVEY_DEVELOPMENT_TEST_DATA_HIT_FORECAST_PURCHASE,
    SURVEY_DEVELOPMENT_TEST_DATA_HIT_FORECAST_RETIREMENT_APARTMENT,
    SURVEY_DEVELOPMENT_TEST_DATA_HIT_INTERIM_FINANCING,
    SURVEY_DEVELOPMENT_TEST_DATA_HIT_PURCHASE,
    SURVEY_DEVELOPMENT_TEST_DATA_HIT_RETIREMENT_APARTMENT,
    SURVEY_DEVELOPMENT_TEST_DATA_LIT_COOPERATIVE_APARTMENT,
    SURVEY_DEVELOPMENT_TEST_DATA_LIT_COOPERATIVE_CONTRIBUTION,
    SURVEY_DEVELOPMENT_TEST_DATA_LIT_FORECAST_COOPERATIVE_APARTMENT,
    SURVEY_DEVELOPMENT_TEST_DATA_LIT_FORECAST_COOPERATIVE_CONTRIBUTION,
    SURVEY_DEVELOPMENT_TEST_DATA_LIT_FORECAST_INTERIM_FINANCING,
    SURVEY_DEVELOPMENT_TEST_DATA_LIT_FORECAST_PURCHASE,
    SURVEY_DEVELOPMENT_TEST_DATA_LIT_FORECAST_RETIREMENT_APARTMENT,
    SURVEY_DEVELOPMENT_TEST_DATA_LIT_INTERIM_FINANCING,
    SURVEY_DEVELOPMENT_TEST_DATA_LIT_PURCHASE,
    SURVEY_DEVELOPMENT_TEST_DATA_LIT_RETIREMENT_APARTMENT,
    SURVEY_DEVELOPMENT_TEST_DATA_MEDIUM_COOPERATIVE_APARTMENT,
    SURVEY_DEVELOPMENT_TEST_DATA_MEDIUM_COOPERATIVE_CONTRIBUTION,
    SURVEY_DEVELOPMENT_TEST_DATA_MEDIUM_INTERIM_FINANCING,
    SURVEY_DEVELOPMENT_TEST_DATA_MEDIUM_PURCHASE,
    SURVEY_DEVELOPMENT_TEST_DATA_MEDIUM_RETIREMENT_APARTMENT,
    SURVEY_DEVELOPMENT_TEST_DATA_UNFEASIBLE_COOPERATIVE_APARTMENT,
    SURVEY_DEVELOPMENT_TEST_DATA_UNFEASIBLE_COOPERATIVE_CONTRIBUTION,
    SURVEY_DEVELOPMENT_TEST_DATA_UNFEASIBLE_INTERIM_FINANCING,
    SURVEY_DEVELOPMENT_TEST_DATA_UNFEASIBLE_PURCHASE,
    SURVEY_DEVELOPMENT_TEST_DATA_UNFEASIBLE_RETIREMENT_APARTMENT,
    SURVEY_DEVELOPMENT_TEST_DATA_WRONG_COOPERATIVE_APARTMENT,
    SURVEY_DEVELOPMENT_TEST_DATA_WRONG_COOPERATIVE_CONTRIBUTION,
    SURVEY_DEVELOPMENT_TEST_DATA_WRONG_INTERIM_FINANCING,
    SURVEY_DEVELOPMENT_TEST_DATA_WRONG_PURCHASE,
    SURVEY_DEVELOPMENT_TEST_DATA_WRONG_RETIREMENT_APARTMENT,
} from "./purchase";
import {
    SURVEY_DEVELOPMENT_TEST_DATA_HIT_REFINANCING,
    SURVEY_DEVELOPMENT_TEST_DATA_LIT_REFINANCING,
    SURVEY_DEVELOPMENT_TEST_DATA_MEDIUM_REFINANCING,
    SURVEY_DEVELOPMENT_TEST_DATA_UNFEASIBLE_REFINANCING,
    SURVEY_DEVELOPMENT_TEST_DATA_WRONG_REFINANCING,
} from "./refinance";
import {
    SURVEY_DEVELOPMENT_TEST_DATA_HIT_CONSTRUCTION,
    SURVEY_DEVELOPMENT_TEST_DATA_LIT_CONSTRUCTION,
    SURVEY_DEVELOPMENT_TEST_DATA_MEDIUM_CONSTRUCTION,
    SURVEY_DEVELOPMENT_TEST_DATA_UNFEASIBLE_CONSTRUCTION,
    SURVEY_DEVELOPMENT_TEST_DATA_WRONG_CONSTRUCTION,
} from "./construction";
import { SURVEY_DEVELOPMENT_TEST_DATA_WRONG_CONSUMER_CREDIT } from "./consumer";

export type SurveyTestDataKey = `${DealPropertyFinancingType}_${SurveyCustomerIntentType}`;

export const SURVEY_TEST_DATA_MAP: Partial<Record<SurveyTestDataKey, Partial<SurveyProperties>>> = {
    [`${DealPropertyFinancingType.PURCHASE}_${SurveyCustomerIntentType.WRONG}`]:
        SURVEY_DEVELOPMENT_TEST_DATA_WRONG_PURCHASE,
    [`${DealPropertyFinancingType.INTERIM_FINANCING}_${SurveyCustomerIntentType.WRONG}`]:
        SURVEY_DEVELOPMENT_TEST_DATA_WRONG_INTERIM_FINANCING,
    [`${DealPropertyFinancingType.RETIREMENT_APARTMENT}_${SurveyCustomerIntentType.WRONG}`]:
        SURVEY_DEVELOPMENT_TEST_DATA_WRONG_RETIREMENT_APARTMENT,
    [`${DealPropertyFinancingType.COOPERATIVE_APARTMENT}_${SurveyCustomerIntentType.WRONG}`]:
        SURVEY_DEVELOPMENT_TEST_DATA_WRONG_COOPERATIVE_APARTMENT,
    [`${DealPropertyFinancingType.COOPERATIVE_CONTRIBUTION}_${SurveyCustomerIntentType.WRONG}`]:
        SURVEY_DEVELOPMENT_TEST_DATA_WRONG_COOPERATIVE_CONTRIBUTION,

    [`${DealPropertyFinancingType.PURCHASE}_${SurveyCustomerIntentType.HIGH}`]:
        SURVEY_DEVELOPMENT_TEST_DATA_HIT_PURCHASE,
    [`${DealPropertyFinancingType.INTERIM_FINANCING}_${SurveyCustomerIntentType.HIGH}`]:
        SURVEY_DEVELOPMENT_TEST_DATA_HIT_INTERIM_FINANCING,
    [`${DealPropertyFinancingType.RETIREMENT_APARTMENT}_${SurveyCustomerIntentType.HIGH}`]:
        SURVEY_DEVELOPMENT_TEST_DATA_HIT_RETIREMENT_APARTMENT,
    [`${DealPropertyFinancingType.COOPERATIVE_APARTMENT}_${SurveyCustomerIntentType.HIGH}`]:
        SURVEY_DEVELOPMENT_TEST_DATA_HIT_COOPERATIVE_APARTMENT,
    [`${DealPropertyFinancingType.COOPERATIVE_CONTRIBUTION}_${SurveyCustomerIntentType.HIGH}`]:
        SURVEY_DEVELOPMENT_TEST_DATA_HIT_COOPERATIVE_CONTRIBUTION,

    [`${DealPropertyFinancingType.PURCHASE}_${SurveyCustomerIntentType.LOW}`]:
        SURVEY_DEVELOPMENT_TEST_DATA_LIT_PURCHASE,
    [`${DealPropertyFinancingType.INTERIM_FINANCING}_${SurveyCustomerIntentType.LOW}`]:
        SURVEY_DEVELOPMENT_TEST_DATA_LIT_INTERIM_FINANCING,
    [`${DealPropertyFinancingType.RETIREMENT_APARTMENT}_${SurveyCustomerIntentType.LOW}`]:
        SURVEY_DEVELOPMENT_TEST_DATA_LIT_RETIREMENT_APARTMENT,
    [`${DealPropertyFinancingType.COOPERATIVE_APARTMENT}_${SurveyCustomerIntentType.LOW}`]:
        SURVEY_DEVELOPMENT_TEST_DATA_LIT_COOPERATIVE_APARTMENT,
    [`${DealPropertyFinancingType.COOPERATIVE_CONTRIBUTION}_${SurveyCustomerIntentType.LOW}`]:
        SURVEY_DEVELOPMENT_TEST_DATA_LIT_COOPERATIVE_CONTRIBUTION,

    [`${DealPropertyFinancingType.PURCHASE}_${SurveyCustomerIntentType.FORECAST_LOW}`]:
        SURVEY_DEVELOPMENT_TEST_DATA_LIT_FORECAST_PURCHASE,
    [`${DealPropertyFinancingType.INTERIM_FINANCING}_${SurveyCustomerIntentType.FORECAST_LOW}`]:
        SURVEY_DEVELOPMENT_TEST_DATA_LIT_FORECAST_INTERIM_FINANCING,
    [`${DealPropertyFinancingType.RETIREMENT_APARTMENT}_${SurveyCustomerIntentType.FORECAST_LOW}`]:
        SURVEY_DEVELOPMENT_TEST_DATA_LIT_FORECAST_RETIREMENT_APARTMENT,
    [`${DealPropertyFinancingType.COOPERATIVE_APARTMENT}_${SurveyCustomerIntentType.FORECAST_LOW}`]:
        SURVEY_DEVELOPMENT_TEST_DATA_LIT_FORECAST_COOPERATIVE_APARTMENT,
    [`${DealPropertyFinancingType.COOPERATIVE_CONTRIBUTION}_${SurveyCustomerIntentType.FORECAST_LOW}`]:
        SURVEY_DEVELOPMENT_TEST_DATA_LIT_FORECAST_COOPERATIVE_CONTRIBUTION,

    [`${DealPropertyFinancingType.PURCHASE}_${SurveyCustomerIntentType.FORECAST_HIGH}`]:
        SURVEY_DEVELOPMENT_TEST_DATA_HIT_FORECAST_PURCHASE,
    [`${DealPropertyFinancingType.INTERIM_FINANCING}_${SurveyCustomerIntentType.FORECAST_HIGH}`]:
        SURVEY_DEVELOPMENT_TEST_DATA_HIT_FORECAST_INTERIM_FINANCING,
    [`${DealPropertyFinancingType.RETIREMENT_APARTMENT}_${SurveyCustomerIntentType.FORECAST_HIGH}`]:
        SURVEY_DEVELOPMENT_TEST_DATA_HIT_FORECAST_RETIREMENT_APARTMENT,
    [`${DealPropertyFinancingType.COOPERATIVE_APARTMENT}_${SurveyCustomerIntentType.FORECAST_HIGH}`]:
        SURVEY_DEVELOPMENT_TEST_DATA_HIT_FORECAST_COOPERATIVE_APARTMENT,
    [`${DealPropertyFinancingType.COOPERATIVE_CONTRIBUTION}_${SurveyCustomerIntentType.FORECAST_HIGH}`]:
        SURVEY_DEVELOPMENT_TEST_DATA_HIT_FORECAST_COOPERATIVE_CONTRIBUTION,

    [`${DealPropertyFinancingType.PURCHASE}_${SurveyCustomerIntentType.UNFEASIBLE}`]:
        SURVEY_DEVELOPMENT_TEST_DATA_UNFEASIBLE_PURCHASE,
    [`${DealPropertyFinancingType.INTERIM_FINANCING}_${SurveyCustomerIntentType.UNFEASIBLE}`]:
        SURVEY_DEVELOPMENT_TEST_DATA_UNFEASIBLE_INTERIM_FINANCING,
    [`${DealPropertyFinancingType.RETIREMENT_APARTMENT}_${SurveyCustomerIntentType.UNFEASIBLE}`]:
        SURVEY_DEVELOPMENT_TEST_DATA_UNFEASIBLE_RETIREMENT_APARTMENT,
    [`${DealPropertyFinancingType.COOPERATIVE_APARTMENT}_${SurveyCustomerIntentType.UNFEASIBLE}`]:
        SURVEY_DEVELOPMENT_TEST_DATA_UNFEASIBLE_COOPERATIVE_APARTMENT,
    [`${DealPropertyFinancingType.COOPERATIVE_CONTRIBUTION}_${SurveyCustomerIntentType.UNFEASIBLE}`]:
        SURVEY_DEVELOPMENT_TEST_DATA_UNFEASIBLE_COOPERATIVE_CONTRIBUTION,

    [`${DealPropertyFinancingType.PURCHASE}_${SurveyCustomerIntentType.MEDIUM}`]:
        SURVEY_DEVELOPMENT_TEST_DATA_MEDIUM_PURCHASE,
    [`${DealPropertyFinancingType.INTERIM_FINANCING}_${SurveyCustomerIntentType.MEDIUM}`]:
        SURVEY_DEVELOPMENT_TEST_DATA_MEDIUM_INTERIM_FINANCING,
    [`${DealPropertyFinancingType.RETIREMENT_APARTMENT}_${SurveyCustomerIntentType.MEDIUM}`]:
        SURVEY_DEVELOPMENT_TEST_DATA_MEDIUM_RETIREMENT_APARTMENT,
    [`${DealPropertyFinancingType.COOPERATIVE_APARTMENT}_${SurveyCustomerIntentType.MEDIUM}`]:
        SURVEY_DEVELOPMENT_TEST_DATA_MEDIUM_COOPERATIVE_APARTMENT,
    [`${DealPropertyFinancingType.COOPERATIVE_CONTRIBUTION}_${SurveyCustomerIntentType.MEDIUM}`]:
        SURVEY_DEVELOPMENT_TEST_DATA_MEDIUM_COOPERATIVE_CONTRIBUTION,

    [`${DealPropertyFinancingType.REFINANCE}_${SurveyCustomerIntentType.HIGH}`]:
        SURVEY_DEVELOPMENT_TEST_DATA_HIT_REFINANCING,
    [`${DealPropertyFinancingType.REFINANCE}_${SurveyCustomerIntentType.LOW}`]:
        SURVEY_DEVELOPMENT_TEST_DATA_LIT_REFINANCING,
    [`${DealPropertyFinancingType.REFINANCE}_${SurveyCustomerIntentType.UNFEASIBLE}`]:
        SURVEY_DEVELOPMENT_TEST_DATA_UNFEASIBLE_REFINANCING,
    [`${DealPropertyFinancingType.REFINANCE}_${SurveyCustomerIntentType.MEDIUM}`]:
        SURVEY_DEVELOPMENT_TEST_DATA_MEDIUM_REFINANCING,
    [`${DealPropertyFinancingType.REFINANCE}_${SurveyCustomerIntentType.WRONG}`]:
        SURVEY_DEVELOPMENT_TEST_DATA_WRONG_REFINANCING,

    [`${DealPropertyFinancingType.CONSTRUCTION}_${SurveyCustomerIntentType.HIGH}`]:
        SURVEY_DEVELOPMENT_TEST_DATA_HIT_CONSTRUCTION,
    [`${DealPropertyFinancingType.CONSTRUCTION}_${SurveyCustomerIntentType.LOW}`]:
        SURVEY_DEVELOPMENT_TEST_DATA_LIT_CONSTRUCTION,
    [`${DealPropertyFinancingType.CONSTRUCTION}_${SurveyCustomerIntentType.UNFEASIBLE}`]:
        SURVEY_DEVELOPMENT_TEST_DATA_UNFEASIBLE_CONSTRUCTION,
    [`${DealPropertyFinancingType.CONSTRUCTION}_${SurveyCustomerIntentType.MEDIUM}`]:
        SURVEY_DEVELOPMENT_TEST_DATA_MEDIUM_CONSTRUCTION,
    [`${DealPropertyFinancingType.CONSTRUCTION}_${SurveyCustomerIntentType.WRONG}`]:
        SURVEY_DEVELOPMENT_TEST_DATA_WRONG_CONSTRUCTION,

    [`${DealPropertyFinancingType.CONSUMER_CREDIT}_${SurveyCustomerIntentType.WRONG}`]:
        SURVEY_DEVELOPMENT_TEST_DATA_WRONG_CONSUMER_CREDIT,
};
