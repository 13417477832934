import {
    SURVEY_PROPERTY_TYPE_PROJECT_STATUS_MAP,
    PURCHASE_INTENT_TYPES,
    SURVEY_REFINANCING_PROJECT_STATUS_VALUES,
    LOW_CUSTOMER_INTENT_TYPES,
    HIGH_CUSTOMER_INTENT_TYPES,
    SURVEY_INTENTION_TO_MINIMUM_REQUIRED_FIELDS_MAP,
} from "../constants";
import { Season, DealPropertyFinancingType, SurveyCustomerIntentType, SurveyProperties } from "../models";
import { isBefore } from "date-fns";

type YearEnd = "yearEnd";

const SEASONS: (Season | YearEnd)[] = [Season.WINTER, Season.SPRING, Season.SUMMER, Season.FALL, "yearEnd"];

/**
 *
 * @param {Date} currentDate the current date to get the season for
 * @returns {Season | YearEnd} the current season, or the special @type {YearEnd}
 * as it's treated different even though it's winter
 */
const getCurrentSeason = (currentDate: Date): Season | YearEnd => {
    const springStart = new Date(currentDate); //march 1st
    springStart.setMonth(2);
    springStart.setDate(1);

    const summerStart = new Date(currentDate); //june 1st
    summerStart.setMonth(5);
    summerStart.setDate(1);

    const fallStart = new Date(currentDate); //september 1st
    fallStart.setMonth(8);
    fallStart.setDate(1);

    const winterStart = new Date(currentDate); //december 1st
    winterStart.setMonth(11);
    winterStart.setDate(1);

    if (isBefore(currentDate, springStart)) {
        return Season.WINTER;
    } else if (isBefore(currentDate, summerStart)) {
        return Season.SPRING;
    } else if (isBefore(currentDate, fallStart)) {
        return Season.SUMMER;
    } else if (isBefore(currentDate, winterStart)) {
        return Season.FALL;
    } else {
        return "yearEnd";
    }
};

/**
 *
 * @param {Date} currentDate the current date to get the construction dates for
 * @returns {string[]} the dates in format "Sommer {year}, Herbst {year}..."
 * for the next 4 seasons, ending with the next year or later as the final option
 */
export const generateRoughConstructionDates = (currentDate = new Date()): string[] => {
    const currentSeason = getCurrentSeason(currentDate);

    const dateCopy = new Date(currentDate);

    const constructionDates: string[] = [];

    let currentSeasonIndex = SEASONS.indexOf(currentSeason);

    // until we have 5 elements for the next 4 seasons + year after
    while (constructionDates.length < 5) {
        const currentYear = dateCopy.getFullYear();
        if (constructionDates.length === 4) {
            // just push the final year
            constructionDates.push(`${currentDate.getFullYear() + 1} oder später`);
        } else {
            const season = SEASONS[currentSeasonIndex];

            constructionDates.push(`${season === "yearEnd" ? Season.WINTER : season} ${currentYear}`);

            // if we reach the end of the current year (winter), advance to the next one
            if (SEASONS[currentSeasonIndex++] === "yearEnd") {
                currentSeasonIndex = 1;
                dateCopy.setFullYear(dateCopy.getFullYear() + 1);
            }
        }
    }

    return constructionDates;
};

export const translateRoughConstructionDate = (t: any, constructionDate: string) => {
    return constructionDate
        .replace(Season.WINTER, t("page.survey.labelMappings.seasons.winter"))
        .replace(Season.SPRING, t("page.survey.labelMappings.seasons.spring"))
        .replace(Season.SUMMER, t("page.survey.labelMappings.seasons.summer"))
        .replace(Season.FALL, t("page.survey.labelMappings.seasons.fall"))
        .replace("oder später", t("page.survey.labelMappings.seasons.later"));
};

/**
 * MOR-1792: Now our project status values depend on the variant. This doesn't just affect the frontend, but there's
 * also some code on the backend that depends on it. As we can't extract it here, instead, when calling for the options,
 * we pass the parameter @param variant to determine the correct options. It's used when determining if the progress
 * value matches possible values when combining with the intent
 * @param {DealPropertyFinancingType} intention the intention to check for
 * @returns {string[]} the list of project status values
 */
export const getProjectStatusValuesForIntention = (intention: DealPropertyFinancingType) => {
    if (!intention) {
        return SURVEY_PROPERTY_TYPE_PROJECT_STATUS_MAP[DealPropertyFinancingType.PURCHASE];
    }

    switch (intention) {
        case DealPropertyFinancingType.REFINANCE: {
            return SURVEY_REFINANCING_PROJECT_STATUS_VALUES;
        }
        default:
            return SURVEY_PROPERTY_TYPE_PROJECT_STATUS_MAP[intention];
    }
};

export const isPurchaseIntention = (intention: DealPropertyFinancingType): boolean => {
    return PURCHASE_INTENT_TYPES.includes(intention);
};

export const isLowCustomerIntentGroup = (intentType: SurveyCustomerIntentType): boolean => {
    return LOW_CUSTOMER_INTENT_TYPES.includes(intentType);
};

export const isHighCustomerIntentGroup = (intentType: SurveyCustomerIntentType): boolean => {
    return HIGH_CUSTOMER_INTENT_TYPES.includes(intentType);
};

/**
 * MOR-1968: Incomplete data synced to Hubspot after Survey submit
 * Sentry reporting: To detect anomalies in contact/deal POST/PUT requests,
 * this util helps us detect if we're missing the crucial data for each project type
 * @param {Partial<SurveyProperties>} surveyProperties
 * @return {boolean} true if all the fields for the intention type are in the surveyProperties, false if not
 */
export const surveyPropertiesHaveMinimumRequiredFields = (surveyProperties: Partial<SurveyProperties>): boolean => {
    const intention = surveyProperties.intention as DealPropertyFinancingType;

    if (!intention) {
        return false;
    }

    const minimumRequiredSurveyProperties = SURVEY_INTENTION_TO_MINIMUM_REQUIRED_FIELDS_MAP[intention];

    if (!minimumRequiredSurveyProperties) {
        return false;
    }

    for (const field of minimumRequiredSurveyProperties) {
        if (!surveyProperties[field]) {
            return false;
        }
    }

    return true;
};

export function isCustomerIntentLowOrUnfeasible(intentType: SurveyCustomerIntentType) {
    return [
        SurveyCustomerIntentType.UNFEASIBLE,
        SurveyCustomerIntentType.LOW,
        SurveyCustomerIntentType.FORECAST_LOW,
    ].includes(intentType);
}
