import { UAParser } from "ua-parser-js";
import { SESSION_ID_COOKIE_KEY } from "src/context/SessionContext";
import { SURVEY_COOKIE_USER_ID } from "src/context/SurveyContext/constants";
import gTagService from "src/service/gtag";
import { Actions } from "src/shared/types/analytics";
import { getCookie } from "src/shared/util/regex";
import { useSurveyEventStore } from "src/store/useSurveyEventStore";
import AbTestingServiceInstance from "../ab-test";
import { config } from "src/config";
import { userCookies } from "src/shared/constants/userCookies";
import { DealProperties } from "@miraclapp/mortgaging-shared";
import { sendAnalyticsEvent } from "../ga-events/api";
import { Language } from "src/shared/types/language";

export type GenericEventParams = {
    action?: Actions;
    [key: string]: any;
};

export const trackEvent = (name: string, params?: GenericEventParams) => {
    gTagService.sendEvent(name, params);
};

export const trackUserEvent = (action: string, params?: object) => {
    const searchParams = new URL(document.location.href).searchParams;
    const commonParams = useSurveyEventStore.getState().data;
    const eventParams = params || {};
    const sessionId = getCookie(SESSION_ID_COOKIE_KEY);
    const userId = getCookie(SURVEY_COOKIE_USER_ID);
    const version = AbTestingServiceInstance.getVersionedVariant();
    const browser = getBrowser();
    const os = getOS();
    const device = getDevice();
    const userCookieParams = userCookies.reduce((acc, cookie) => {
        const value = searchParams.get(cookie.name) ?? getCookie(cookie.name);
        return {
            ...acc,
            [cookie.name]: value ? decodeURIComponent(value) : value,
        };
    }, {});
    const referrer = getCookie("referrer");

    const payload = {
        ...eventParams,
        ...commonParams,
        ...userCookieParams,
        app: "mortgaging",
        referrer: referrer ? decodeURIComponent(referrer) : undefined,
        version,
        environment: config.environment,
        sessionId,
        userId,
        hostname: window.location.hostname,
        url: `${window.location.pathname}${window.location.search}`,
        browser,
        os,
        device,
        systemLanguage: navigator.language,
        language: searchParams.get("lang") || Language.DE,
    };

    window["userEvents"] = window["userEvents"] || [];
    window["userEvents"].push({ action, ...payload });

    trackEvent(action, payload);
};

export const trackPotentialHomeOwner = (deal: DealProperties) => {
    const searchParams = new URL(document.location.href).searchParams;
    const browser = getBrowser();
    const os = getOS();
    const device = getDevice();

    sendAnalyticsEvent({
        action: "UserPotentialHomeOwner",
        params: {
            intention: deal.surveyIntention,
            intentType: deal.customerIntent,
            app: "mortgaging",
            version: deal.surveyVersion,
            environment: config.environment,
            sessionId: null,
            userId: deal.user_id,
            hostname: window.location.hostname,
            url: `${window.location.pathname}${window.location.search}`,
            utm_campaign: deal.utm_campaign,
            utm_content: deal.utm_content,
            utm_medium: deal.utm_medium,
            utm_source: deal.utm_source,
            utm_term: deal.utm_term,
            fbclid: deal.hs_facebook_click_id,
            gclid: deal.hs_google_click_id,
            browser,
            os,
            device,
            systemLanguage: navigator.language,
            language: searchParams.get("lang") || Language.DE,
        },
    });
};
function getBrowser() {
    const parser = new UAParser();
    const { name, version } = parser.getBrowser();
    return `${name} ${version}`;
}

function getOS() {
    const parser = new UAParser();
    const { name, version } = parser.getOS();
    return `${name} ${version}`;
}

function getDevice() {
    const hasTouch = "ontouchstart" in window || navigator.maxTouchPoints > 0;

    if (window.innerWidth < 640) {
        return hasTouch ? "phone" : "desktop";
    }

    if (window.innerWidth < 992) {
        return hasTouch ? "tablet" : "desktop";
    }

    return "desktop";
}
