import { useCallback, useEffect, useState } from "react";
import { storageService } from "../../../service/storage";
import { LocalStorageKey } from "../../types/storage";
import { fromStorageToState } from "../../util/storage";

export const useStoredState = <T>(key: LocalStorageKey, defaultValue: T) => {
    const [data, setData] = useState<T>(defaultValue);

    const updateData = useCallback(
        (value: T) => {
            storageService.set(key, value);
            setData(value);
        },
        [key],
    );

    useEffect(() => {
        fromStorageToState(key, defaultValue, setData);
    }, [key, defaultValue]);

    return {
        data,
        updateData,
    };
};
