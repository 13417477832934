import { config } from "src/config";
import { axiosApiInstance } from "../api/axiosApiInstance";

const url = `${config.server.url}/analytics/event`;

type SendGaEventPayload = {
    action: string;
    params: any;
};

export function sendAnalyticsEvent(payload: SendGaEventPayload) {
    if (navigator.sendBeacon) {
        navigator.sendBeacon(url, new Blob([JSON.stringify(payload)], { type: "application/json" }));
    } else {
        axiosApiInstance.post(url, payload);
    }
}
