import { RequiredDocumentJobType } from "../models";

type SameKeyValueRecord<Keys extends string> = {
    [v in Keys]: v;
};

const inferGenericType = <T extends string>(obj: SameKeyValueRecord<T>) => {
    return obj;
};

export const categories = inferGenericType({
    bank: "bank",
    customer: "customer",
    property: "property",
    miracl: "miracl",
} as const);

export const banks = inferGenericType({
    erste: "erste",
    raiffeisen: "raiffeisen",
    oberbank: "oberbank",
    wüstenrot: "wüstenrot",
    HYPO: "HYPO",
} as const);

export const persons = inferGenericType({
    kreditnehmer1: "kreditnehmer1",
    kreditnehmer2: "kreditnehmer2",
    kreditnehmer3: "kreditnehmer3",
    kreditnehmer4: "kreditnehmer4",
} as const);

export const bankDocumentTypes = inferGenericType({
    vertraglandesfoerderung: "vertraglandesfoerderung",
    aushaftungLandesfoerderung: "aushaftungLandesfoerderung",
    datenblatt: "datenblatt",
    liegenschaftsbewertung: "liegenschaftsbewertung",
    finanzierungsanfrage: "finanzierungsanfrage",
    vermittlungsauftrag: "vermittlungsauftrag",
    bedarfsanalyse: "bedarfsanalyse",
    DSGVO: "DSGVO",
    einverstandniserklarung: "einverstandniserklarung",
    selbstauskunft: "selbstauskunft",
    stellungnahme: "stellungnahme",
    liegenschaftsdatenblatt: "liegenschaftsdatenblatt",
    hikRGSelbstauskunft: "hikRGSelbstauskunft",
    HHPRLB: "HHPRLB",
    SC: "SC",
    kundenerklärung: "kundenerklärung",
    planRechnungDreiJahre: "planRechnungDreiJahre",
    bankSonstiges: "bankSonstiges",
} as const);

export const personalDocumentTypes = inferGenericType({
    reisepass: "reisepass",
    meldezettel: "meldezettel",
    lohnabrechnungen: "lohnabrechnungen",
    jahreslohnzettel: "jahreslohnzettel",
    eigenmittelnachweis: "eigenmittelnachweis",
    wiedereinstellungszusage: "wiedereinstellungszusage",
    dienstvertrag: "dienstvertrag",
    karenzgeldbescheid: "karenzgeldbescheid",
    vertragKonsumkredit: "vertragKonsumkredit",
    aktuelleAushaftungKonsumkredit: "aktuelleAushaftungKonsumkredit",
    schreibenGehaltserhöhung: "schreibenGehaltserhöhung",
    gebietskrankenkassenauszug: "gebietskrankenkassenauszug",
    pensionszettel: "pensionszettel",
    steuerbescheide: "steuerbescheide",
    steuererklärungen: "steuererklärungen",
    jahresabschlüsse: "jahresabschlüsse",
    saldenliste: "saldenliste",
    steuerkonto: "steuerkonto",
    firmenbuchauszug: "firmenbuchauszug",
    aufenthaltstitel: "aufenthaltstitel",
    nachweisFamilienbeihilfe: "nachweisFamilienbeihilfe",
    aktuellerAuszugSVA: "aktuellerAuszugSVA",
    nachweisZuZahlendeAlimente: "nachweisZuZahlendeAlimente",
    nachweisZuErhaltendeAlimente: "nachweisZuErhaltendeAlimente",
    kontoumsätze: "kontoumsätze",
    berufsUnfähigkeitsPensionsbescheid: "berufsUnfähigkeitsPensionsbescheid",
    kundenunterlagenSonstiges: "kundenunterlagenSonstiges",
    schufaAuskunft: "schufaAuskunft",
    abfertigung: "abfertigung",
    scheidungsvergleich: "scheidungsvergleich",
    scheidungsbeschluss: "scheidungsbeschluss",
    auszugOGK: "auszugOGK",
    kundenplanung: "kundenplanung",
    gesellschaftsvertrag: "gesellschaftsvertrag",
    rückzahlungsbestätigung: "rückzahlungsbestätigung",
    heiratsurkunde: "heiratsurkunde",
    asylbescheid: "asylbescheid",
} as const);

export const objectDocumentTypes = inferGenericType({
    expose: "expose",
    fotosAußen: "fotosAußen",
    fotosKüche: "fotosKüche",
    fotosBad: "fotosBad",
    fotosWohnzimmer: "fotosWohnzimmer",
    fotosSchlafzimmer: "fotosSchlafzimmer",
    fotosAndereRäume: "fotosAndereRäume",
    grundrisse: "grundrisse",
    grundbuch: "grundbuch",
    wohnungseigentumsvertrag: "wohnungseigentumsvertrag",
    schenkungsvertrag: "schenkungsvertrag",
    kaufanbot: "kaufanbot",
    kaufvertrag: "kaufvertrag",
    kreditvertrag: "kreditvertrag",
    aktuelleAushaftung: "aktuelleAushaftung",
    optionsvertrag: "optionsvertrag",
    mietvertrag: "mietvertrag",
    baukostenaufstellung: "baukostenaufstellung",
    energieausweis: "energieausweis",
    flächenwidmungsplan: "flächenwidmungsplan",
    fotosInnen: "fotosInnen",
    kostenvoranschlägeRechnungen: "kostenvoranschlägeRechnungen",
    löschungsquittung: "löschungsquittung",
    nachweisMieteinnahmen: "nachweisMieteinnahmen",
    gutachten: "gutachten",
    betriebskostenabrechnung: "betriebskostenabrechnung",
    objektunterlagenSonstiges: "objektunterlagenSonstiges",
    miraclSonstiges: "miraclSonstiges",
    einreichplan: "einreichplan",
    fotosKeller: "fotosKeller",
    fotosHeizung: "fotosHeizung",
    altePläne: "altePläne",
    bauAusstattungsbeschreibung: "bauAusstattungsbeschreibung",
} as const);

export const documentTypes = inferGenericType({
    ...bankDocumentTypes,
    ...personalDocumentTypes,
    ...objectDocumentTypes,
} as const);

export const objects = inferGenericType({
    object1: "object1",
    object2: "object2",
    object3: "object3",
    object4: "object4",
    object5: "object5",
} as const);

export const miraclTypes = inferGenericType({
    kreditangebot: "kreditangebot",
    miraclKreditvertrag: "miraclKreditvertrag",
    esis: "esis",
    kreditvergleich: "kreditvergleich",
    beratungsprotokoll: "beratungsprotokoll",
    pfandurkunde: "pfandurkunde",
    bankAustria: "bankAustria",
    ksvAuszug: "ksvAuszug",
    treuhandschreiben: "treuhandschreiben",
    angebot: "angebot",
} as const);

export const REQUIRED_DOCUMENT_JOB_TYPES = Object.values(RequiredDocumentJobType);
