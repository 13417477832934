import { useMemo } from "react";
import { useCookies } from "react-cookie";
import { SURVEY_COOKIE_USER_ID } from "../constants";
import { getCookieOptions } from "src/utils/cookie";
import { addYears } from "date-fns";
import { generateId } from "src/utils/generateId";

export const useUserId = (): string => {
    const [cookies, setCookie] = useCookies([SURVEY_COOKIE_USER_ID]);
    const cookieUserId = cookies[SURVEY_COOKIE_USER_ID];

    return useMemo((): string => {
        if (cookieUserId) return cookieUserId;

        const cookieValue = generateId();

        setCookie(SURVEY_COOKIE_USER_ID, cookieValue, {
            ...getCookieOptions(),
            expires: addYears(new Date(), 1),
        });

        return cookieValue.toString();
    }, [cookieUserId, setCookie]);
};
