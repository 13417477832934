import { Milestone } from "../models/milestone";

// Mapping milestones to representative Hubspot fields for when the values get synced
export const MILESTONE_HUBSPOT_FIELD_MAP: Record<Milestone, string> = {
    [Milestone.CATEGORIZE_ESIS]: "esis__mandatory_",
    [Milestone.CATEGORIZE_MORTGAGE_OFFER]: "kreditangebot__mandatory_",
    [Milestone.CATEGORIZE_CONSULTATION_PROTOCOL]: "beratungsprotokoll__mandatory_",
    [Milestone.CATEGORIZE_MORTGAGE_COMPARISON]: "kreditvergleich__mandatory_",
    [Milestone.CATEGORIZE_MORTGAGE_CONTRACT]: "kreditvertrag__mandatory_",
    [Milestone.CATEGORIZE_CONSULTATION_AGREEMENT]: "vermittlungsauftrag__mandatory_",
    [Milestone.DOCUMENT_UPLOADED]: "dokumente_hochgeladen",
    [Milestone.PANDADOC_CREATED]: "pandadoc_erstellt",
    [Milestone.PANDADOC_SENT]: "pandadoc_sent",
    [Milestone.PANDADOC_COMPLETED]: "pandadoc_completed",
    [Milestone.CONSULTATION_STARTED]: "consultation_started",
    [Milestone.CONSULTATION_MORTGAGE_COMPARISON]: "consultation_mortgage_comparison",
    [Milestone.CUSTOMER_APPOINTMENT_BOOKING]: "customer_appointment_booking",
    [Milestone.EXPORT_FIXED_PERMIT]: "export_fixed_permit",
};
