import { addYears, addDays } from "../util/date";

const oneYearFromNow = addYears(new Date(), 1);
const oneDayFromNow = addDays(new Date(), 1);

export type Cookie = {
    name: string;
    expires?: Date;
};

export const userCookies: Cookie[] = [
    { name: "utm_campaign", expires: oneDayFromNow },
    { name: "utm_content", expires: oneDayFromNow },
    { name: "utm_medium", expires: oneDayFromNow },
    { name: "utm_source", expires: oneDayFromNow },
    { name: "utm_term", expires: oneDayFromNow },
    { name: "source", expires: oneDayFromNow },
    { name: "packageVersion", expires: oneDayFromNow },
    { name: "fbclid", expires: oneYearFromNow },
    { name: "gclid", expires: oneYearFromNow },
];
