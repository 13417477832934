import {
    AdditionalEmploymentEnumerator,
    BorrowerEnumerator,
    ExistingPropertyEnumerator,
    FinancingOptionEnumerator,
    AdditionalAskedBankEnumerator,
    AdditionalIncomeEnumerator,
} from "@miraclapp/mortgaging-shared";

export const BORROWER_ENUMERATOR_KEYS: BorrowerEnumerator[] = ["Borrower1", "Borrower2", "Borrower3", "Borrower4"];

export const EXISTING_PROPERTY_ENUMERATOR_KEYS: ExistingPropertyEnumerator[] = [
    "existingProperty1",
    "existingProperty2",
    "existingProperty3",
    "existingProperty4",
];

export const FINANCING_OPTION_ENUMERATOR_KEYS: FinancingOptionEnumerator[] = [
    "Option1",
    "Option2",
    "Option3",
    "Option4",
];

export const ADDITIONAL_EMPLOYMENT_ENUMERATOR_KEYS: AdditionalEmploymentEnumerator[] = [
    "additionalEmployment1",
    "additionalEmployment2",
];

export const ADDITIONAL_INCOME_ENUMERATOR_KEYS: AdditionalIncomeEnumerator[] = [
    "additionalIncome1",
    "additionalIncome2",
    "additionalIncome3",
    "additionalIncome4",
];

export const ADDITIONAL_ASKED_BANKS_ENUMERATOR_KEYS: AdditionalAskedBankEnumerator[] = [
    "AdditionalAskedBank1",
    "AdditionalAskedBank2",
    "AdditionalAskedBank3",
    "AdditionalAskedBank4",
];
