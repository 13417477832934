import { storageService } from "../../service/storage";
import { LocalStorageKey } from "../types/storage";

export const fromStorageToState = async <T>(
    storageKey: LocalStorageKey,
    defaultState: T,
    setState: React.Dispatch<React.SetStateAction<T>>,
) => {
    const value = storageService.getParsed<T>(
        storageKey,
        defaultState,
        typeof defaultState === "string" ? (value) => value as any : undefined,
    );

    setState(value ?? defaultState);
};
