import { axiosApiInstance } from "src/service/api/axiosApiInstance";
import { config as appConfig } from "src/config";

const base = `${appConfig.server.url}/analytics`;

export const trackConversionEvent = async (
    gclid: string,
    conversionId: string,
    value: number,
    currency = "EUR",
    timestamp = Date.now(),
): Promise<void> => {
    const url = `${base}/conversions/event`;

    return axiosApiInstance.post(url, {
        gclid,
        conversionId,
        value,
        currency,
        timestamp,
    });
};
