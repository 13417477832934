import { FirebaseApp, initializeApp } from "firebase/app";
import {
    Auth,
    connectAuthEmulator,
    getAuth,
    GoogleAuthProvider,
    signInWithPopup,
    signInWithEmailAndPassword,
    User,
    createUserWithEmailAndPassword,
} from "firebase/auth";

const firebaseConfig = {
    apiKey: process.env.VITE_FIREBASE_API_KEY,
    authDomain: process.env.VITE_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.VITE_FIREBASE_PROJECT_ID,
    storageBucket: process.env.VITE_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.VITE_FIREBASE_APP_ID,
};

const app = initializeApp(firebaseConfig);

type GoogleLoginOptions = {
    strategy: "google";
};

type CredentialsLoginOptions = {
    strategy: "credentials";
    data: CredentialsLoginData;
};

export type CredentialsLoginData = {
    email: string;
    password: string;
};

export type LoginOptions = GoogleLoginOptions | CredentialsLoginOptions;

class AuthService {
    private auth: Auth;

    constructor(application: FirebaseApp) {
        this.auth = getAuth(application);
    }

    private assertAuth() {
        if (!this.auth) {
            throw new Error("Auth service not initialized.");
        }
    }

    public getAuth() {
        this.assertAuth();
        return this.auth;
    }

    public async login(options: LoginOptions) {
        this.assertAuth();
        const { strategy } = options;
        if (strategy === "google") {
            const provider = new GoogleAuthProvider();
            provider.setCustomParameters({ prompt: "select_account" });
            await signInWithPopup(this.auth, provider);
        }

        if (strategy === "credentials") {
            const { data } = options;
            await signInWithEmailAndPassword(this.auth, data.email, data.password);
        }
    }

    public async signup(credentials: CredentialsLoginData) {
        await createUserWithEmailAndPassword(this.auth, credentials.email, credentials.password);
    }

    public async logout() {
        this.assertAuth();
        await this.auth.signOut();
    }

    public async available(user: User): Promise<boolean> {
        this.assertAuth();

        await user.reload();
        return true;
    }
}

export const AuthServiceInstance = new AuthService(app);

if (process.env.VITE_FIREBASE_AUTH_EMULATOR_URL) {
    connectAuthEmulator(AuthServiceInstance.getAuth(), process.env.VITE_FIREBASE_AUTH_EMULATOR_URL);
}
