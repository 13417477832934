import { DealProperties, Role } from "@miraclapp/mortgaging-shared";
import { useCallback, useContext } from "react";
import { LocalStorageKey } from "../types/storage";
import { useStoredState } from "./storage/useStoredState";
import { AuthContext } from "src/context/AuthContext";

interface RecentDeal {
    id: string;
    firstName: string;
    lastName: string;
    timestamp: number;
    companyId?: string;
}

interface RecentDealsStorage {
    version: "v2";
    data: RecentDeal[];
}

const DEFAULT_RECENT_DEALS_STORAGE: RecentDealsStorage = { version: "v2", data: [] };
const RECENT_DEALS_MAX_CAPACITY = 25;

export const useRecentDeals = (capacity = RECENT_DEALS_MAX_CAPACITY) => {
    const { profile } = useContext(AuthContext);
    const { data: recentDealsData, updateData } = useStoredState<RecentDealsStorage>(
        LocalStorageKey.RECENT_DEALS,
        DEFAULT_RECENT_DEALS_STORAGE,
    );

    const updateRecentDeals = useCallback(
        (id: string, properties: DealProperties) => {
            if (!recentDealsData || !id || !properties) {
                return;
            }

            let updatedRecentDeals: RecentDeal[] = recentDealsData?.data;

            // If we detect that the version is not v2, it means the storage is old, so we have to clear old
            // formated values and continue storing the new v2 format
            if (recentDealsData?.version !== "v2" || !updatedRecentDeals?.length) {
                updatedRecentDeals = [];
            }

            const existingIndex = updatedRecentDeals.findIndex((deal) => String(deal.id) === String(id));

            // If the entry exists and is at the top we skip the update
            if (existingIndex === 0) {
                return;
            }

            // If it's not at the top we remove it so we can move it to the top
            if (existingIndex !== -1) {
                updatedRecentDeals.splice(existingIndex, 1);
            }

            // Adding the new element to the recent deals storage
            updatedRecentDeals.unshift({
                id,
                firstName: properties.firstNameBorrower1,
                lastName: properties.lastNameBorrower1,
                timestamp: Date.now(),
                companyId: profile?.companyId,
            });

            // If the capacity is reached we remove the last element before adding another one
            if (updatedRecentDeals.length > capacity) {
                updatedRecentDeals.pop();
            }

            updateData({ version: "v2", data: updatedRecentDeals });
        },
        [profile, capacity, recentDealsData, updateData],
    );

    const recentDeals =
        recentDealsData?.data?.filter((item) => {
            return (!item.companyId && profile?.role !== Role.COMPANY) || item.companyId === profile?.companyId;
        }) || [];

    return {
        recentDeals,
        updateRecentDeals,
    };
};
