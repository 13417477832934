import { Alert, Grid, Typography, useTheme } from "@mui/material";
import { useTranslation } from "src/context/LocaleContext";

interface Props {
    message: string;
}

export const ToolErrorBoundaryContainer = ({ message }: Props) => {
    const { t } = useTranslation();
    const theme = useTheme();

    return (
        <Grid container sx={{ width: "100%", height: "100%", justifyContent: "center", flex: 1 }}>
            <Grid container item sx={{ maxWidth: "1000px", padding: 4, flexDirection: "column", gap: 2 }}>
                <Typography variant="h1" textAlign={"center"}>
                    {t("errorBoundary.tool.title")}
                </Typography>
                <Grid container item flex={1} flexDirection={"column"} marginTop={"5%"} gap={3}>
                    <Grid
                        container
                        item
                        sx={{
                            borderStyle: "solid",
                            borderRadius: 2,
                            padding: 2,
                            borderColor: "gray",
                            maxHeight: "400px",
                            overflowY: "auto",
                        }}
                    >
                        <Typography
                            fontSize={18}
                            color={theme.palette.error.light}
                            sx={{ display: "flex", gap: 1, alignItems: "center" }}
                        >
                            {message}
                        </Typography>
                    </Grid>
                    <Alert
                        severity="warning"
                        sx={{
                            fontSize: 18,
                            "& .MuiAlert-icon": {
                                fontSize: 40,
                            },
                            alignItems: "center",
                        }}
                    >
                        {t("errorBoundary.tool.message")}
                    </Alert>
                </Grid>
            </Grid>
        </Grid>
    );
};
