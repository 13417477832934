import { DealProperties } from "@miraclapp/mortgaging-shared";
import { Typography } from "@mui/material";
import React, { FC, useCallback, useContext, useEffect } from "react";
import { getDealPropertiesBackup } from "../service/deal/api";
import {
    BankApplicationProtocol,
    FinancingEstimateProtocol,
    FinancingOptionProtocol,
    RequestEstimateProtocol,
    RequestProtocol,
} from "../service/mortgage-engine/types";
import { AuthContext } from "./AuthContext";
import { useTranslation } from "./LocaleContext";
import { useNotificationContext } from "./notification/NotificationContext";
import { useRecentDeals } from "src/shared/hooks/useRecentDeals";
import { useActiveDeal } from "src/shared/hooks/useActiveDeal";
import { useNavigator } from "src/shared/hooks/useNavigator";
import { ApplicationRoute } from "src/shared/types/route";

export type GetFinancingOptionsResponse = {
    options: FinancingOptionProtocol[];
    financingRequestEstimate: RequestEstimateProtocol;
    requestFilled: RequestProtocol;
    applications: Record<string, BankApplicationProtocol>;
};

export type GetEstimatesResponse = { requestEstimate: RequestEstimateProtocol; estimate: FinancingEstimateProtocol };

type DealContextData = {
    activeDeal: DealProperties;
    activeDealLoading: boolean;
    updateActiveDeal: (deal: Partial<DealProperties>) => void;
    resetDealContext: () => void;
    restoreDealProperties: () => Promise<Partial<DealProperties> | undefined>;
    loadActiveDeal: (id: string) => void;
};

const initialDealContextData: DealContextData = {
    activeDeal: undefined,
    activeDealLoading: false,
    updateActiveDeal: (deal) => ({}),
    resetDealContext: () => {},
    restoreDealProperties: async () => undefined,
    loadActiveDeal: (id: string) => undefined,
};

export const DealContext = React.createContext<DealContextData>(initialDealContextData);

export const DealContextProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
    const { t } = useTranslation();
    const { notify } = useNotificationContext();
    const { applicationUser } = useContext(AuthContext);
    const { navigate } = useNavigator();

    const { activeDeal, loadActiveDeal, updateActiveDeal, activeDealLoading } = useActiveDeal({
        onError: useCallback(
            (err: any) => {
                notify("modal", "error", {
                    title: t("common.error"),
                    body: <Typography textAlign="justify">{t("notification.hubspot.deal_fetch_error")}</Typography>,
                    button: { label: t("common.cancel") },
                });

                if (err.response.status === 404) {
                    navigate(ApplicationRoute.ERROR, { search: { code: "dealNotFound" } });
                }
            },
            [navigate, t, notify],
        ),
    });

    const { updateRecentDeals } = useRecentDeals();

    const resetDealContext = useCallback(() => {
        updateActiveDeal(undefined);
    }, [updateActiveDeal]);

    const restoreDealProperties = useCallback(async () => {
        const dealPropertiesBackup = await getDealPropertiesBackup(applicationUser, activeDeal?.id);

        if (!dealPropertiesBackup) {
            return undefined;
        }

        if (!dealPropertiesBackup.data?.data) {
            return undefined;
        }

        updateActiveDeal(dealPropertiesBackup.data.data);

        return dealPropertiesBackup.data?.data;
    }, [activeDeal, applicationUser, updateActiveDeal]);

    useEffect(() => {
        if (!activeDeal || !activeDeal.id) {
            return;
        }

        updateRecentDeals(activeDeal.id, activeDeal);
    }, [activeDeal, updateRecentDeals]);

    return (
        <DealContext.Provider
            value={{
                activeDeal,
                activeDealLoading,
                updateActiveDeal,
                resetDealContext,
                restoreDealProperties,
                loadActiveDeal,
            }}
        >
            {children}
        </DealContext.Provider>
    );
};
