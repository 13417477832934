export enum EventType {
    COMMON_EVENT = "CommonEvent",
    HOME_PAGE_EVENT = "HomePageEvent",
    CALCULATOR_EVENT = "CalculatorEvent",
    BANKS_EVENT = "BanksEvent",
    PROPERTY_EVENT = "PropertyEvent",
    DEAL_EVENT = "DealEvent",
    DOCUMENT_EVENT = "DocumentEvent",
    SUBMISSION_EVENT = "SubmissionEvent",
    SETTINGS_EVENT = "SettingsEvent",
    CONSULTATION_EVENT = "ConsultationEvent",
}

export enum SettingsActions {
    TOGGLE_NEXT_DOCUMENT_ON_SAVE = "ToggleNextDocumentOnSave",
    TOGGLE_BANK_NAME_VISIBLE = "ToggleBankNameVisible",
    TOGGLE_DISPLAY_SNOWFLAKES = "ToggleDisplaySnowflakes",
    TOGGLE_DISPLAY_CHRISTMAS_LIGHTS = "ToggleDisplayChristmasLights",
    TOGGLE_CONSULTATION_DISPLAY_OWN_PHOTO = "ToggleConsultationDisplayOwnPhoto",
}

export enum CommonActions {
    NAVIGATE = "Navigate",
    OPEN_NOTES_BY_BUTTON = "OpenNotesByButton",
    TOGGLE_NOTES_BY_SHORTCUT = "ToggleNotesByShortcut",
    LOGOUT = "Logout",
}

export enum HomePageActions {
    LOAD_DEAL_DATA = "LoadDealData",
}

export enum CalculatorActions {
    SHOW_OPTIONS_AND_SYNC = "ShowOptionsAndSync",
    SHOW_OPTIONS = "ShowOptions",
    USER_INPUT_MODIFIED = "UserInputModified",
}

export enum BanksActions {
    TOGGLE_OPTIONS_WITH_WARNINGS = "ToggleOptionsWithWarnings",
    TOGGLE_OPTIONS_WITH_ERRORS = "ToggleOptionsWithErrors",
    EDIT_DOWNPAYMENT_BY_SLIDER = "EditDownPaymentBySlider",
    EDIT_DOWNPAYMENT_BY_INPUT = "EditDownPaymentByInput",
    EDIT_FIXED_RUNTIME = "EditFixedRuntime",
    EDIT_TOTAL_RUNTIME = "EditTotalRuntime",
    EDIT_REPAYMENT_FREE_PERIOD = "EditRepaymentFreePeriod",
    SELECT_OBJECT_PURPOSE = "SelectObjectPurpose",
    OPEN_COMPARE_OPTIONS = "OpenCompareOptions",
    SAVE_OPTIONS = "SaveOptions",
    CLEAR_OPTIONS = "ClearOptions",
    TOGGLE_BANK_NAME_VISIBILITY = "ToggleBankNameVisibility",
    TOGGLE_PREVIEW_MODE = "TogglePreviewMode",
    EXPORT_COMPARISON_PDF = "ExportComparisonPdf",
    FINANCING_OPTION_MODIFIED = "FinancingOptionModified",
}

export enum DealActions {
    NAVIGATE_BY_SECTION = "NavigateBySection",
    TOGGLE_BANK_FIELDS = "ToggleBankFields",
    SYNC_DEAL_PROPERTIES = "SyncDealProperties",
    RESTORE_DEAL_PROPERTIES = "RestoreDealProperties",
}

export enum DocumentActions {
    EDIT_DOCUMENT = "EditDocument",
    DRAG_AND_DROP_CATEGORIZE = "DragAndDropCategorize",
    SYNC = "Sync",
    EXPORT = "Export",
    PREVIEW = "Preview",
    SPLIT = "Split",
    ROTATE = "Rotate",
    ROTATE_DOCUMENT = "RotateDocument",
    ROTATE_MULTIPLE_DOCUMENTS = "RotateMultipleDocuments",
    MERGE = "Merge",
    UPLOAD_MULTIPLE = "UploadMultiple",
    UPLOAD_SINGLE = "UploadSingle",
    EDIT_FILTER_PARAMS = "EditFilterParams",
    DELETE_MULTIPLE = "DeleteMultiple",
    DELETE_SINGLE = "DeleteSingle",
    EDIT_CHECKLIST_ADD_CHECKLIST_ELEMENT = "EditChecklistAddChecklistElement",
    EDIT_CHECKLIST_SORT_LIST = "EditChecklistSortList",
    EDIT_CHECKLIST_REMOVE_CHECKLIST_ELEMENT = "EditChecklistRemoveChecklistElement",
    EDIT_CHECKLIST_SAVE = "EditChecklistSave",
    OPEN_EDIT_CHECKLIST_MODAL_VIA_BUTTON = "OpenEditChecklistModalViaButton",
    OPEN_EDIT_CHECKLIST_MODAL_VIA_NAVIGATION = "OpenEditChecklistModalViaNavigation",
    OPEN_CREATE_DOCUMENT_MODAL_VIA_NAVIGATION = "OpenCreateDocumentModalViaNavigation",
}

export enum SubmissionActions {
    EXPORT_BANK_DEAL = "ExportBankDeal",
}

export enum ConsultationActions {
    NAVIGATED_BETWEEN_STEPS = "NavigatedBetweenSteps",
    NAVIGATED_WITH_MISSING_FIELDS = "NavigatedWithMissingFields",
}

export enum SurveyActions {
    USER_SURVEY_BOUNCE_TIME = "UserSurveyBounceTime",
    USER_SURVEY_FIRST_INTERACTION = "UserSurveyFirstInteraction",
    USER_START_SURVEY = "UserStartSurvey",
    USER_SOURCE = "UserSource",
    USER_START_BOOK_APPOINTMENT_MODE = "UserStartBookAppointmentMode",
    USER_START_SOLUTIONS_MODE = "UserStartSolutionsMode",
    USER_START_ADDITIONAL_INFO = "UserStartAdditionalInfo",
    USER_START_SURVEY_STEP = "UserStartSurveyStep",
    USER_INTENT = "UserIntent",
    USER_FINISH_SURVEY_STEP = "UserFinishSurveyStep",
    USER_SELECT_PURCHASE = "UserSelectPurchase",
    USER_SELECT_REFINANCING = "UserSelectRefinancing",
    USER_SELECT_CONSTRUCTION = "UserSelectConstruction",
    USER_SELECT_CONSUMER_CREDIT = "UserSelectConsumerCredit",
    USER_SELECT_OTHER = "UserSelectOther",
    USER_ENTER_NAME = "UserEnterName",
    USER_STEP_INTENT = "UserStepIntent",
    USER_ENTER_PHONE_NUMBER = "UserEnterPhoneNumber",
    USER_ENTER_EMAIL = "UserEnterEmail",
    USER_FINISH_SURVEY = "UserFinishSurvey",
    USER_REACH_SOLUTIONS = "UserReachSolutions",
    USER_START_BOOK_APPOINTMENT = "UserStartBookAppointment",
    USER_BOOKED_APPOINTMENT = "UserBookedAppointment",
    USER_BOOKED_WILDCARD_APPOINTMENT = "UserBookedWildcardAppointment",
    USER_EMPTY_PHONE_NUMBER_STEP = "EmptyPhoneNumberStep",
    USER_INTERACT_SOLUTIONS = "UserInteractSolutions",
    USER_INTERACT_FAQ = "UserInteractFaq",
    USER_INTERACT_TOP_AVATAR = "UserInteractTopAvatar",
    USER_INTERACT_BOOKING_CALENDAR = "UserInteractBookingCalendar",
    USER_INTERACT_BOOKING_TIMESLOT = "UserInteractBookingTimeslot",
    USER_INTERACT_TOOLTIP = "UserInteractTooltip",
    USER_LTV = "UserLtv",
    USER_DSTI = "UserDsti",
    USER_INTERACT_SOLUTIONS_OPEN_FILTERS = "UserInteractSolutionsOpenFilters",
    USER_INTERACT_SOLUTIONS_CHANGE_TERM = "UserInteractSolutionsChangeTerm",
    USER_INTERACT_SOLUTIONS_CHANGE_FIX_TERM = "UserInteractSolutionsChangeFixTerm",
    USER_INTERACT_SOLUTIONS_CLICK_CTA = "UserInteractSolutionsClickCta",
    USER_INTERACT_SOLUTIONS_CLICK_SHOW_MORE = "UserInteractSolutionsClickShowMore",
    USER_INTERACT_SOLUTIONS_CLICK_COLLAPSE = "UserInteractSolutionsClickCollapse",
    META_SOLUTION_COUNT = "MetaSolutionCount",
    USER_SUBMIT_ANSWER = "UserSubmitAnswer",
    USER_IS_RECURRING = "UserIsRecurring",
    USER_ESTIMATED_PROPERTY = "UserEstimatedProperty",
    USER_SKIPPED_PROPERTY_ESTIMATE = "UserSkippedPropertyEstimate",
    USER_PROCESS_STEP_VIDEO_STARTED = "UserProcessVideoStarted",
    USER_COOKIE_CONSENT = "UserCookieConsent",
    USER_FEEDBACK = "UserFeedback",
    USER_CHANGE_LANGUAGE = "UserChangeLanguage",
}

export enum AdditionalInfoActions {
    USER_START_ADDITIONAL_INFO = "UserStartAdditionalInfo",
    USER_START_ADDITIONAL_INFO_STEP = "UserStartAdditionalInfoStep",
    USER_FINISH_ADDITIONAL_INFO_STEP = "UserFinishAdditionalInfoStep",
    USER_SUBMIT_ADDITIONAL_INFO_ANSWER = "UserSubmitAdditionalInfoAnswer",
    USER_FINISH_ADDITIONAL_INFO = "UserFinishAdditionalInfo",
}

export enum HubActions {
    USER_UPLOADED_DOCUMENT = "UserUploadedDocument",
}

export type Actions =
    | CommonActions
    | HomePageActions
    | CalculatorActions
    | BanksActions
    | DealActions
    | DocumentActions
    | SubmissionActions
    | SettingsActions
    | ConsultationActions
    | SurveyActions
    | AdditionalInfoActions
    | HubActions;
