export const selectAllOnFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    event?.target?.select?.();
};

//cannot figure out the proper type for this event that has the .select()
export const selectAllOnClick = (event) => {
    event?.target?.select?.();
};

export const safeInputValue = (value: any): string => {
    if (value || Number(value) === 0) return String(value);
    return "";
};
