import { DealProperties } from "@miraclapp/mortgaging-shared";
import { useCallback, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { getDeal } from "../../service/deal/api";
import { sentryService } from "../../service/sentry";
import { ApplicationRoute } from "../types/route";
import { APPLICATION_CUSTOMER_ROUTES } from "../constants/route";
import { useNavigator } from "./useNavigator";
import { DEFAULT_DEAL_PROPERTIES_FORM_FIELDS } from "../constants/deal";

interface Props {
    /**
     * Side effect to be ran when the active deal fails to be loaded successfully
     *
     * @param err Error object
     * @returns void
     */
    onError?: (err: Error) => void;
}

/**
 * Hook which purpose is to store state of application active deal and provide
 * functions necessary to load a new active deal and to update the current active deal
 */
export const useActiveDeal = ({ onError }: Props) => {
    const { applicationUser } = useContext(AuthContext);
    const location = useLocation();
    const { navigate } = useNavigator();

    const [activeDeal, setActiveDeal] = useState<DealProperties>();
    const [activeDealLoading, setActiveDealLoading] = useState(false);

    const updateActiveDeal = useCallback((deal: DealProperties, cleanUpdate = false) => {
        setActiveDeal((current) => {
            let updated = {
                ...DEFAULT_DEAL_PROPERTIES_FORM_FIELDS,
            } as DealProperties;

            try {
                if (!cleanUpdate) {
                    updated = { ...updated, ...current };
                }

                updated = { ...updated, ...deal };

                if (!updated?.id) {
                    throw new Error("Active deal does not have an ID.");
                }
            } catch (error) {
                sentryService.report(error);
            } finally {
                return updated;
            }
        });
    }, []);

    const loadActiveDeal = useCallback(
        async (dealId: string, route: string = ApplicationRoute.CUSTOMER_DATA) => {
            if (!dealId) {
                return;
            }

            try {
                setActiveDealLoading(true);

                const response = await getDeal(applicationUser, dealId);
                const responseData = response.data.data?.dealData;
                const responseDataId = responseData?.id;

                if (!responseData) {
                    throw new Error("[LOAD ACTIVE DEAL] Deal response invalid");
                }

                if (!responseDataId) {
                    throw new Error("[LOAD ACTIVE DEAL] Deal data not available");
                }

                console.log("[LOAD ACTIVE DEAL] Response data:", responseData);

                updateActiveDeal(responseData, true);

                navigate(route, { search: { dealId }, preserveParams: true });
            } catch (error) {
                sentryService.report(error);
                onError?.(error);
            } finally {
                setActiveDealLoading(false);
            }
        },
        [applicationUser, navigate, onError, updateActiveDeal],
    );

    /**
     * Automatically loading a deal if the app route is detected in the form of:
     *  /deal/:dealId:
     *
     * or if a search param is present in the form of:
     *  dealId?=:dealId:
     *
     * Customer pages are skipped
     */
    useEffect(() => {
        if (APPLICATION_CUSTOMER_ROUTES.includes(location.pathname)) {
            return;
        }

        // First we check for the route in the form of /deal/:dealId:
        const [, applicationRoute, dealId] = location.pathname.split("/");
        const isDealRoute = `/${applicationRoute}` === ApplicationRoute.DEAL;

        if (isDealRoute && dealId && dealId.localeCompare(activeDeal?.id) !== 0) {
            loadActiveDeal(dealId);
            return;
        }

        // Then we try to handle deal loading with the dealId query param
        const searchParams = new URL(window.location.href).searchParams;
        const searchParamsId = searchParams.get("dealId");

        if (searchParamsId && searchParamsId.localeCompare(activeDeal?.id) !== 0) {
            loadActiveDeal(searchParamsId, location.pathname);
        }
    }, [activeDeal?.id, loadActiveDeal, location.pathname]);

    return {
        activeDeal,
        activeDealLoading,
        loadActiveDeal,
        updateActiveDeal,
    };
};
