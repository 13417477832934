import { BorrowerIncomeCurrencyValues, Country, DealProperties } from "@miraclapp/mortgaging-shared";
import {
    BORROWER_ENUMERATOR_KEYS,
    EXISTING_PROPERTY_ENUMERATOR_KEYS,
    ADDITIONAL_EMPLOYMENT_ENUMERATOR_KEYS,
    ADDITIONAL_INCOME_ENUMERATOR_KEYS,
    ADDITIONAL_ASKED_BANKS_ENUMERATOR_KEYS,
} from "src/constants/enumerator";
import { defaultInterestRate } from "../types/engine";
import { SalariesPerYearValues } from "../types/deal";
import { createDefaultHousehold } from "../hooks/dealDataForm/useHouseholdsField";

export const MIN_NUMBER_OF_BORROWERS = 1;
export const MAX_NUMBER_OF_BORROWERS = BORROWER_ENUMERATOR_KEYS.length;
export const DEFAULT_NUMBER_OF_BORROWERS = 1;

export const MIN_NUMBER_OF_HOUSEHOLDS = 1;
export const MAX_NUMBER_OF_HOUSEHOLDS = 4;
export const DEFAULT_NUMBER_OF_HOUSEHOLDS = 1;

export const MIN_NUMBER_OF_EXISTING_PROPERTIES = 0;
export const MAX_NUMBER_OF_EXISTING_PROPERTIES = EXISTING_PROPERTY_ENUMERATOR_KEYS.length;
export const DEFAULT_NUMBER_OF_EXISTING_PROPERTIES = 0;

export const MIN_NUMBER_OF_ADDITIONAL_EMPLOYMENTS = 0;
export const MAX_NUMBER_OF_ADDITIONAL_EMPLOYMENTS = ADDITIONAL_EMPLOYMENT_ENUMERATOR_KEYS.length;
export const DEFAULT_NUMBER_OF_ADDITIONAL_EMPLOYMENTS = 0;

export const MIN_NUMBER_OF_ADDITIONAL_INCOMES = 0;
export const MAX_NUMBER_OF_ADDITIONAL_INCOMES = ADDITIONAL_INCOME_ENUMERATOR_KEYS.length;
export const DEFAULT_NUMBER_OF_ADDITIONAL_INCOMES = 0;

export const MIN_NUMBER_OF_ADDITIONAL_LIABILITIES = 0;
export const MAX_NUMBER_OF_ADDITIONAL_LIABILITIES = 10;
export const DEFAULT_NUMBER_OF_ADDITIONAL_LIABILITIES = 0;

export const MIN_NUMBER_OF_ADDITIONAL_ASKED_BANKS = 0;
export const MAX_NUMBER_OF_ADDITIONAL_ASKED_BANKS = ADDITIONAL_ASKED_BANKS_ENUMERATOR_KEYS.length;
export const DEFAULT_NUMBER_OF_ADDITIONAL_ASKED_BANKS = 0;

export const MIN_NUMBER_OF_ADDITIONAL_WEALTH = 0;
export const MAX_NUMBER_OF_ADDITIONAL_WEALTH = 10;
export const DEFAULT_NUMBER_OF_ADDITIONAL_WEALTH = 0;

export const DEFAULT_DEAL_PROPERTIES_FORM_FIELDS = {
    // Financing default values
    affordabilityInterestRate: defaultInterestRate,
    agencyFee: 3,
    estateAgentFee: 3.6,
    purchaseContractCreationFee: 2,
    realEstateTransferFee: 3.5,
    propertyRegistrationFee: 1.1,
    earlyRepaymentPenalty: 1,

    // Borrower default values
    salaryCurrencyBorrower1: BorrowerIncomeCurrencyValues.EUR,
    salaryCurrencyBorrower2: BorrowerIncomeCurrencyValues.EUR,
    salaryCurrencyBorrower3: BorrowerIncomeCurrencyValues.EUR,
    salaryCurrencyBorrower4: BorrowerIncomeCurrencyValues.EUR,
    numberOfSalariesBorrower1: SalariesPerYearValues.SALARIES_14,
    numberOfSalariesBorrower2: SalariesPerYearValues.SALARIES_14,
    numberOfSalariesBorrower3: SalariesPerYearValues.SALARIES_14,
    numberOfSalariesBorrower4: SalariesPerYearValues.SALARIES_14,

    // General default values
    numberOfBorrowers: 1,
    numberOfExistingProperties: 0,

    // Financing property default values
    propertyCountry: Country.AUSTRIA,

    // It's important that array fields have default values
    households: [createDefaultHousehold()],
    additionalLiabilities: [],
    fixedRateRuntimeMin: 5 * 12,
    fixedRateRuntime: 25 * 12,
    totalRuntime: 35 * 12,
    totalRuntimeMin: 30 * 12,
} as DealProperties;
