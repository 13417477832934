import { User } from "firebase/auth";
import { config } from "../../config";
import { axiosApiInstance } from "../api/axiosApiInstance";
import { buildInternalRequestConfig } from "../api/helpers";
import { Specialist } from "@miraclapp/mortgaging-shared";

const base = `${config.server.url}`;

export const getUserProfile = async (currentUser: User): Promise<{ data: Specialist }> => {
    const config = await buildInternalRequestConfig(currentUser);
    const response = await axiosApiInstance.get(`${base}/profile`, config);
    return response.data as unknown as { data: Specialist };
};
