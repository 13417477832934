import qs from "qs";
import { useCallback } from "react";
import { NavigateOptions, To, useNavigate } from "react-router-dom";

type CustomNavigateOptions = NavigateOptions & {
    search?: object;
    preserveParams?: boolean;
};

export function useNavigator() {
    const baseNavigate = useNavigate();

    const navigate = useCallback(
        (to: To | number, options: CustomNavigateOptions = {}) => {
            if (typeof to === "number") {
                return baseNavigate(to);
            }

            const url = new URL(window.location.href);
            const { search = {}, preserveParams = false, ...rest } = options;
            const currentQuery = preserveParams ? Object.fromEntries(url.searchParams.entries()) : {};
            const queryString = qs.stringify({ ...currentQuery, ...search });

            return baseNavigate(`${to}${queryString ? `?${queryString}` : ""}`, rest);
        },
        [baseNavigate],
    );

    return { navigate };
}
