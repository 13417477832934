import * as Yup from "yup";
import { Shape } from "../types";
import { numberValidation, stringOptionValidation, stringValidation } from "../util";
import { VARIABLE_INTEREST_RATE_REFERENCES } from "../../../constants";
import { DealProperties } from "../../../models";

export const fundingDetailsSchema = Yup.object<Shape<Partial<DealProperties>>>({
    stressRate: numberValidation(0),
    fixedInterestRate: numberValidation(0),
    variableInterestRate: numberValidation(0),
    variableInterestRateEURIBOR: stringOptionValidation(VARIABLE_INTEREST_RATE_REFERENCES),
    debitAccountIBAN: stringValidation(),
});
