import { useEffect } from "react";

export const usePageTitle = (pathname: string, pathTitleMap: Record<string, string>, defaultTitle: string = "") => {
    useEffect(() => {
        if (!pathname || !pathTitleMap) return;

        const pageTitle = pathTitleMap[pathname] ?? defaultTitle;

        document.title = pageTitle;
    }, [defaultTitle, pathTitleMap, pathname]);
};
