import { DealPropertyKestTaxStatus } from "../models";

export const WHY_NO_TIN_OPTIONS: string[] = [
    "Das Land vergibt keine TIN",
    "Ich habe den TIN beantragt, aber noch nicht erhalten",
    "Sonstige Begründung",
];

export const WHY_MAIN_RESIDENCE_DIFFERS_OPTIONS: string[] = [
    "Auslandsstudent/in",
    "ausländische Pflegekraft",
    "Diplomatische Vertrerung",
    "Sonstige schlüssige Begründung",
];

export const WEALTH_SECURITY_OPTIONS: string[] = [
    "Immobilien",
    "Versicherungen",
    "Wertpapiere und Spargutgaben",
    "sonstigen Vermögen",
];

export const KEST_TAX_STATUS_OPTIONS = Object.values(DealPropertyKestTaxStatus);

export const BUILDING_IN_CONSTRUCTION_VALUE = "in Bau";
export const BUILDING_SANITARY_RENOVATION_IN_PROGRESS_VALUE = "Sanierungs-/renovierungsbedürftig";

export const BUILDING_STATUS_OPTIONS: string[] = [
    "Erstbezug/Neuwertig",
    "Normal erhalten",
    BUILDING_SANITARY_RENOVATION_IN_PROGRESS_VALUE,
    BUILDING_IN_CONSTRUCTION_VALUE,
];

export const RENOVATION_TYPE_OPTIONS: string[] = ["nicht renoviert", "renoviert", "teilsaniert", "vollsaniert"];

export const DSGVO_VALUE = "DSGVO";
export const IMMAZING_RESULTS_VALUE = "Ergebnis ImmAzing Quick";
export const LOAN_AMOUNT_VALUE = "Kreditbetrag lt. FINE Antrag";

export const REQUIRED_DOCUMENTS_CHECKLIST_OPTIONS: string[] = [
    DSGVO_VALUE,
    "Energieausweis",
    "aktuelle Fotos/ Projektvisualisierungen im .jpg Format",
    "Pläne/ Grundrissplan",
    "Kaufvertrag, Kaufanbot",
    IMMAZING_RESULTS_VALUE,
    LOAN_AMOUNT_VALUE,
];

export const LOCATION_AREA_VALUES: string[] = ["Gerwerbegebiet", "Innenstadt/Zentrum", "Mischgebiet", "Wohngebiet"];

export const RENTING_CONTRACT_LIMITED_UNTIL = "befristet bis";

export const RENTING_CONTRACT_DURATION_VALUES: string[] = [RENTING_CONTRACT_LIMITED_UNTIL, "unbefristet"];

export const PROPERTY_FEATURES_VALUES: string[] = [
    "Ferienhaus-/Wohnung",
    "Abstellraum",
    "zentral begehbar",
    "klimatisiert",
    "Barrierefreiheit",
    "Badezimmer hat Fenster",
];

export const PROPERTY_LEGAL_SPECIFICS_VALUES: string[] = ["keine", "Baurecht", "Superädifikat / Pachtgrund"];
