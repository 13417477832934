export const parseSearchParams = (searchParams: string): Record<string, string> => {
    if (!searchParams) {
        return {};
    }
    const removedQuestionMark = searchParams.replace("?", "");
    return removedQuestionMark.split("&").reduce((acc, curr) => {
        const [key, value] = curr.split("=");
        return {
            ...acc,
            ...(key && value && { [key]: value }),
        };
    }, {});
};
