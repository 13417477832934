import { AppointmentType } from "../models";

export const APPOINTMENT_TYPE_VALUES: AppointmentType[] = [AppointmentType.A1, AppointmentType.A2, AppointmentType.CE1];

export const GOOGLE_CALENDAR_EVENT_NAME_MAP: Record<AppointmentType, string> = {
    [AppointmentType.A1]: "miracl Bankenvergleich",
    [AppointmentType.A2]: "miracl - Detailbesprechung & Beauftragung",
    [AppointmentType.CE1]: "CE1",
};

export enum BookAppointmentErrorReason {
    PAST_TIME_SLOT = "pastTimeSlot",
    APPOINTMENTS_OVERBOOKED = "appointmentsOverbooked",
    UNSUPPORTED_APPOINTMENT_TYPE = "unsupportedAppointmentType",
    UNKNOWN = "unknown",
}
