import {
    BorrowerEmploymentType,
    BorrowerReferralType,
    DealPropertyFinancingType,
    SurveyBankOption,
    SurveyFinancingCompletion,
    SurveyHouseBankFinancingPreference,
    SurveyHowCanWeHelpYou,
    SurveyHowSoonFinancingCommitmentNeededPurchase,
    SurveyProperties,
    SurveyPurchaseProgress,
} from "../../../models";

export const SURVEY_DEVELOPMENT_TEST_DATA_WRONG_CONSUMER_CREDIT: Partial<SurveyProperties> = {
    intention: DealPropertyFinancingType.CONSUMER_CREDIT,
    price: 300_000,
    availableCapital: 50_000,
    untilWhenFinancingCompleted: SurveyFinancingCompletion.AS_SOON_AS_POSSIBLE,
    pax: "1 Person",
    primaryPersonNumberOfSalaries: 14,
    occupation: BorrowerEmploymentType.SELF_EMPLOYED,
    primaryPersonIncome: 4_500,
    purchaseProgress: SurveyPurchaseProgress.HELD_CONVERSATION_WITH_BANKS,
    howSoonFinancingCommitmentNeeded: SurveyHowSoonFinancingCommitmentNeededPurchase.ASAP_WANT_TO_MAKE_AN_OFFER,
    whichBanksAreHouseBanks: SurveyBankOption.ERSTE,
    houseBankFinancingPreference: SurveyHouseBankFinancingPreference.NONE,
    howCanWeHelpYou: SurveyHowCanWeHelpYou.VIEW_TOP_CONDITIONS,
    referral: BorrowerReferralType.GOOGLE,
    firstName: "Max",
    email: "test@miracl.at",
};
