import { useEffect } from "react";
import AbTestingServiceInstance from "src/service/ab-test";
import { config } from "src/config";
import gTagService from "src/service/gtag";
import { SURVEY_COOKIE_GCLID } from "src/context/SurveyContext/constants";
import { useCookies } from "react-cookie";
import { useSearchParams } from "react-router-dom";

interface DataLayerEvent {}

interface ExtendedWindow extends Window {
    dataLayer: DataLayerEvent[];
    gtag: any;
}

const useGoogleAnalytics = (
    userId: string,
    trackingId = config.google.trackingId,
    version = AbTestingServiceInstance.getVersionedVariant(),
) => {
    const [searchParams] = useSearchParams();
    const [cookies] = useCookies([SURVEY_COOKIE_GCLID]);
    const cookieGclid = cookies[SURVEY_COOKIE_GCLID];

    /* Parse the gclid from the url first, then the cookie else default to null */
    const gclid: string | null = searchParams.get("gclid") || cookieGclid || null;

    useEffect(() => {
        const isAnalyticsAlreadyAdded = document.querySelector(`script[src*="gtag/js?id=${trackingId}"]`);

        if (isAnalyticsAlreadyAdded) {
            return;
        }

        const script = document.createElement("script");
        script.async = true;
        script.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`;
        document.head.appendChild(script);

        const windowWithAnalytics = window as unknown as ExtendedWindow;
        windowWithAnalytics.dataLayer = windowWithAnalytics.dataLayer || [];

        windowWithAnalytics.gtag = function gtag() {
            // @ts-ignore
            windowWithAnalytics.dataLayer.push(arguments);
        };

        windowWithAnalytics.gtag("js", new Date());
        windowWithAnalytics.gtag("config", trackingId, {
            user_id: userId,
        });

        windowWithAnalytics.gtag("set", "user_properties", { version });

        windowWithAnalytics.gtag("event", "page_view", { page_path: window.location.pathname });

        gTagService.init(gclid);
    }, [trackingId, version, userId, gclid]);
};

export default useGoogleAnalytics;
