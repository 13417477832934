import { ApiResponse, Privilege, Role, RolePrivileges } from "@miraclapp/mortgaging-shared";
import { User } from "firebase/auth";
import { config } from "../../config";
import { axiosApiInstance } from "../api/axiosApiInstance";
import { buildInternalRequestConfig } from "../api/helpers";

const base = `${config.server.url}`;

export const getPrivileges = async (currentUser: User) => {
    const config = await buildInternalRequestConfig(currentUser);
    return axiosApiInstance.get<ApiResponse<RolePrivileges>>(`${base}/role`, config);
};

export const getPrivilegesByRole = async (currentUser: User, role: Role) => {
    const config = await buildInternalRequestConfig(currentUser);
    return axiosApiInstance.get<ApiResponse<Privilege[]>>(`${base}/role/privileges?role=${role}`, config);
};
