import { SESSION_ID_LOCAL_STORAGE_KEY } from "./../../shared/constants/token";
import axios from "axios";
import { AxiosResponse } from "axios";
import { REFRESH_TOKEN_LOCAL_STORAGE_KEY } from "src/shared/constants/token";
import { refreshSessionToken } from "./../auth/api";
import { UnauthorizedMessage } from "@miraclapp/mortgaging-shared";
import { withRetry } from "src/utils/withRetry";

const axiosApiInstance = withRetry(axios.create());

let axiosApiInstanceSetup = false;

const logFormattedResponse = (response: AxiosResponse<any, any>) => {
    console.log({
        url: response.config.url,
        status: response.status,
        headers: response.headers,
    });
};

const initializeAxiosApiInstance = (unauthorizedReroute: () => void) => {
    if (axiosApiInstanceSetup) {
        return;
    }

    const successInterceptor = (response) => {
        logFormattedResponse(response);
        return response;
    };

    const errorInterceptor = async function (error) {
        const originalRequest = error.config;
        const errorResponse = error?.response;

        if (errorResponse) {
            logFormattedResponse(errorResponse);
        }

        if (errorResponse?.status === 307 && !originalRequest._retry) {
            originalRequest._retry = true;

            const response = await refreshSessionToken(localStorage.getItem(REFRESH_TOKEN_LOCAL_STORAGE_KEY));
            localStorage.setItem(REFRESH_TOKEN_LOCAL_STORAGE_KEY, response.data.data.refreshToken);
            localStorage.setItem(SESSION_ID_LOCAL_STORAGE_KEY, response.data.data.sessionId);
            originalRequest.headers.Authorization = `Bearer ${response.data.data.sessionId}`;

            return axiosApiInstance(originalRequest);
        } else if (errorResponse?.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;

            if (errorResponse?.data?.includes(UnauthorizedMessage.AUTHORIZATION_FAILED)) {
                unauthorizedReroute();
            }

            return axiosApiInstance(originalRequest);
        }

        return Promise.reject(error);
    };

    axiosApiInstance.interceptors.response.use(successInterceptor, errorInterceptor);

    axiosApiInstanceSetup = true;
};

export { axiosApiInstance, initializeAxiosApiInstance };
