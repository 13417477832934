import { AxiosError, AxiosResponse } from "axios";

export const round = (value: number, decimals = 2): number => {
    const factor = Math.pow(10, decimals);
    return Math.round(value * factor) / factor;
};

export const sleep = (time: number) => new Promise((resolve) => setTimeout(resolve, time));

export const downloadURI = (uri: string) => {
    const link = document.createElement("a");
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

export const validateResponse = (response: AxiosResponse) => {
    if (response instanceof AxiosError) {
        throw response;
    }
};

export const deepCopy = <T>(data: T): T => {
    return JSON.parse(JSON.stringify(data)) as T;
};

export const removeEmptyElements = (array: any[]) => {
    if (!array) return [];
    return array.filter((element) => element);
};

export const range = (start: number, end: number, step = 1) => {
    const len = Math.floor((end - start) / step) + 1;
    return Array(len)
        .fill(0)
        .map((_, idx) => start + idx * step);
};
