import { Grid, SxProps } from "@mui/material";
import { orange } from "@mui/material/colors";
import { Children, FC, ReactElement } from "react";
import "./style.scss";

interface GlowingBorderWrapperProps {
    disabled?: boolean;
    glowColor?: string;
    inheritSx?: boolean;
    children?: React.ReactNode;
    sx?: SxProps;
}

/**
 * Used to add a glowing border around anything
 * Only supports one child element, if more are present
 * the component will generate a run time error
 */
export const GlowingBorderWrapper: FC<GlowingBorderWrapperProps> = ({
    children,
    disabled = false,
    glowColor = orange[300],
    inheritSx = false,
    sx = {},
}) => {
    const childSxProps = (children as ReactElement)?.props?.sx;

    return (
        <Grid
            item
            container
            sx={{
                ...(inheritSx ? childSxProps : {}),
                ...(disabled
                    ? {}
                    : {
                          borderRadius: 2,
                          border: `2px solid ${glowColor}`,
                          boxShadow: `0 0 8px ${glowColor}`,
                          marginY: "-2px",
                          animation: "border-pulsate 2s infinite",
                          ...sx,
                      }),
            }}
        >
            {Children.only(children)}
        </Grid>
    );
};
