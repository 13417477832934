import { useCallback, useEffect, useState } from "react";
import { storageService } from "../../service/storage";

export interface UseLocalStorageResponse<T> {
    state: T | undefined;
    set: (data: T | undefined) => void;
    merge: (data: Partial<T> | undefined) => void;
    clear: () => void;
}

export const useLocalStorage = <T>(
    key: string,
    parse = false,
    defaultValue: T = undefined,
): UseLocalStorageResponse<T> => {
    const [state, setState] = useState<T | undefined>();

    const set = useCallback(
        (value: T) => {
            storageService.set(key, value);
            setState(value);
        },
        [key],
    );

    const clear = useCallback(() => {
        storageService.remove(key);
        setState(undefined);
    }, [key]);

    const merge = useCallback(
        (value: T) => {
            setState((prev) => {
                const newVal = { ...prev, ...value };
                storageService.set(key, newVal);
                return newVal;
            });
        },
        [key],
    );

    useEffect(() => {
        const value = parse ? storageService.getParsed<T>(key) : (storageService.get(key) as unknown as T);
        if (value instanceof Object) {
            setState({ ...defaultValue, ...(value ?? {}) });
        } else {
            setState(value ?? defaultValue);
        }
    }, [key, parse, defaultValue]);

    return { state, set, merge, clear };
};
