import { Request } from "express";

export enum AppointmentType {
    CE1 = "CE1",
    A1 = "A1",
    A2 = "A2",
}

export type AppointmentEntry = {
    id: string;
    type: AppointmentType;
    startTimestamp: number; // UTC timestamp;
    endTimestamp: number; // UTC timestamp;
    specialistEmail: string;
    customerEmail?: string;
    customerPhone?: string;
    wildcard: boolean;
    createdAt?: number;
};

export type AppointmentEntryWithDisplayedTimeslots = AppointmentEntry & {
    displayedStartTimestamp: number; // UTC timestamp;
    displayedEndTimestamp: number; // UTC timestamp;
};

export type CreateAppointmentEntry = {
    id: string;
    dealId?: string;
    type: AppointmentType;
    startTimestamp: number; // UTC timestamp;
    specialistEmail: string;
    customerEmail: string;
    customerPhone: string;
    timeFrameInMinutes: number;
    wildcard: boolean;
    summary?: string;
    surveyPropertiesEntryId?: string;
    isTestingMode?: boolean;
};

export type GetAvailableAppointmentsQuery = {
    type: AppointmentType;
    specialistEmails: string[];
    days: number;
    timeFrameInMinutes: number;
    flatten: boolean;
    wildcard: boolean;
    isTestingMode?: boolean;
};

export type GetAvailableAppointmentsRequest = Request & {
    query: GetAvailableAppointmentsQuery;
};

export type GetBookedAppointmentsQuery = {
    startTimestamp: number;
    endTimestamp: number;
};

export type GetBookedAppointmentsRequest = Request & {
    query: GetBookedAppointmentsQuery;
};

export type PostAppointmentRequest = Omit<Request, "body"> & {
    body: CreateAppointmentEntry & { dealId: string };
};
