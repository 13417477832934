import { ON_SEASON_CONSTRUCTION_TIMELINE } from "../../../utils/customer";
import {
    BorrowerEmploymentType,
    BorrowerReferralType,
    DealPropertyFinancingType,
    JaNeinValue,
    SurveyBankOption,
    SurveyConstructionCostsAlreadyAvailable,
    SurveyConstructionEstimateRecieved,
    SurveyConstructionProjectStatus,
    SurveyHouseBankFinancingPreference,
    SurveyProperties,
    SurveyPropertyUsage,
    SurveyPurchaseProgress,
} from "../../../models";

export const SURVEY_DEVELOPMENT_TEST_DATA_HIT_CONSTRUCTION: Partial<SurveyProperties> = {
    intention: DealPropertyFinancingType.CONSTRUCTION,
    usage: SurveyPropertyUsage.OWN_USE,
    progress: SurveyConstructionProjectStatus.LAND_AREA_AVAILABLE,
    constructionCosts: 300_000,
    constructionIncludesLandPurchase: JaNeinValue.JA,
    availableCapital: 80_000,
    realEstate: JaNeinValue.NEIN,
    landPurchasePrice: 50_000,
    submissionPlanReady: "Ja",
    constructionCostsAlreadyAvailable: SurveyConstructionCostsAlreadyAvailable.YES,
    constructionEstimateRecieved: SurveyConstructionEstimateRecieved.YES,
    roughConstructionStartDate: ON_SEASON_CONSTRUCTION_TIMELINE[0],
    pax: "1 Person",
    primaryPersonNumberOfSalaries: 14,
    occupation: BorrowerEmploymentType.SELF_EMPLOYED,
    primaryPersonIncome: 4_500,
    purchaseProgress: SurveyPurchaseProgress.HELD_CONVERSATION_WITH_BANKS,
    howSoonFinancingCommitmentNeeded: ON_SEASON_CONSTRUCTION_TIMELINE[0],
    whichBanksAreHouseBanks: SurveyBankOption.ERSTE,
    houseBankFinancingPreference: SurveyHouseBankFinancingPreference.NONE,
    referral: BorrowerReferralType.GOOGLE,
    firstName: "Max",
    email: "test@miracl.at",
    consentedMarketingEmails: "false",
    phone: "123123123",
};

export const SURVEY_DEVELOPMENT_TEST_DATA_LIT_CONSTRUCTION: Partial<SurveyProperties> = {
    intention: DealPropertyFinancingType.CONSTRUCTION,
    usage: SurveyPropertyUsage.OWN_USE,
    progress: SurveyConstructionProjectStatus.LOOKING_FOR_A_PROPERTY,
    constructionCosts: 300_000,
    constructionIncludesLandPurchase: JaNeinValue.JA,
    availableCapital: 80_000,
    realEstate: JaNeinValue.NEIN,
    landPurchasePrice: 50_000,
    submissionPlanReady: "Ja",
    constructionCostsAlreadyAvailable: SurveyConstructionCostsAlreadyAvailable.YES,
    constructionEstimateRecieved: SurveyConstructionEstimateRecieved.YES,
    roughConstructionStartDate: ON_SEASON_CONSTRUCTION_TIMELINE[0],
    pax: "1 Person",
    primaryPersonNumberOfSalaries: 14,
    occupation: BorrowerEmploymentType.SELF_EMPLOYED,
    primaryPersonIncome: 4_500,
    purchaseProgress: SurveyPurchaseProgress.HELD_CONVERSATION_WITH_BANKS,
    howSoonFinancingCommitmentNeeded: ON_SEASON_CONSTRUCTION_TIMELINE[0],
    whichBanksAreHouseBanks: SurveyBankOption.ERSTE,
    houseBankFinancingPreference: SurveyHouseBankFinancingPreference.NONE,
    referral: BorrowerReferralType.GOOGLE,
    firstName: "Max",
    email: "test@miracl.at",
    consentedMarketingEmails: "false",
    phone: "123123123",
    hasExistingBankOffer: JaNeinValue.JA,
    existingBankOfferPayoutAmount: 300000,
    existingBankOfferDocumentPath: "/existingBankOffers/testid",
};

export const SURVEY_DEVELOPMENT_TEST_DATA_UNFEASIBLE_CONSTRUCTION: Partial<SurveyProperties> = {
    intention: DealPropertyFinancingType.CONSTRUCTION,
    usage: SurveyPropertyUsage.OWN_USE,
    progress: SurveyConstructionProjectStatus.LAND_AREA_AVAILABLE,
    constructionCosts: 300_000,
    constructionIncludesLandPurchase: JaNeinValue.JA,
    availableCapital: 80_000,
    realEstate: JaNeinValue.NEIN,
    landPurchasePrice: 50_000,
    submissionPlanReady: "Ja",
    constructionCostsAlreadyAvailable: SurveyConstructionCostsAlreadyAvailable.YES,
    constructionEstimateRecieved: SurveyConstructionEstimateRecieved.YES,
    roughConstructionStartDate: ON_SEASON_CONSTRUCTION_TIMELINE[0],
    pax: "1 Person",
    primaryPersonNumberOfSalaries: 14,
    occupation: BorrowerEmploymentType.SELF_EMPLOYED,
    primaryPersonIncome: 500,
    purchaseProgress: SurveyPurchaseProgress.HELD_CONVERSATION_WITH_BANKS,
    howSoonFinancingCommitmentNeeded: ON_SEASON_CONSTRUCTION_TIMELINE[0],
    whichBanksAreHouseBanks: SurveyBankOption.ERSTE,
    houseBankFinancingPreference: SurveyHouseBankFinancingPreference.NONE,
    referral: BorrowerReferralType.GOOGLE,
    firstName: "Max",
    email: "test@miracl.at",
    consentedMarketingEmails: "false",
    phone: "123123123",
    hasExistingBankOffer: JaNeinValue.JA,
    existingBankOfferPayoutAmount: 300000,
    existingBankOfferDocumentPath: "/existingBankOffers/testid",
};

export const SURVEY_DEVELOPMENT_TEST_DATA_MEDIUM_CONSTRUCTION: Partial<SurveyProperties> = {
    intention: DealPropertyFinancingType.CONSTRUCTION,
    usage: SurveyPropertyUsage.OWN_USE,
    progress: SurveyConstructionProjectStatus.LAND_AREA_AVAILABLE,
    constructionCosts: 300_000,
    constructionIncludesLandPurchase: JaNeinValue.JA,
    availableCapital: 80_000,
    realEstate: JaNeinValue.NEIN,
    landPurchasePrice: 50_000,
    submissionPlanReady: "Ja",
    constructionCostsAlreadyAvailable: SurveyConstructionCostsAlreadyAvailable.YES,
    constructionEstimateRecieved: SurveyConstructionEstimateRecieved.YES,
    roughConstructionStartDate: ON_SEASON_CONSTRUCTION_TIMELINE[0],
    pax: "1 Person",
    primaryPersonNumberOfSalaries: 14,
    occupation: BorrowerEmploymentType.SELF_EMPLOYED,
    primaryPersonIncome: 4_500,
    purchaseProgress: SurveyPurchaseProgress.HELD_CONVERSATION_WITH_BANKS,
    howSoonFinancingCommitmentNeeded: ON_SEASON_CONSTRUCTION_TIMELINE[0],
    whichBanksAreHouseBanks: SurveyBankOption.ERSTE,
    houseBankFinancingPreference: SurveyHouseBankFinancingPreference.NONE,
    referral: BorrowerReferralType.MIRACL_WORKER,
    firstName: "Max",
    email: "test@miracl.at",
    consentedMarketingEmails: "false",
    phone: "123123123",
};

export const SURVEY_DEVELOPMENT_TEST_DATA_WRONG_CONSTRUCTION: Partial<SurveyProperties> = {
    intention: DealPropertyFinancingType.CONSTRUCTION,
    usage: SurveyPropertyUsage.COMMERCIAL,
    progress: SurveyConstructionProjectStatus.LAND_AREA_AVAILABLE,
    constructionCosts: 300_000,
    constructionIncludesLandPurchase: JaNeinValue.JA,
    availableCapital: 80_000,
    realEstate: JaNeinValue.NEIN,
    landPurchasePrice: 50_000,
    submissionPlanReady: "Ja",
    constructionCostsAlreadyAvailable: SurveyConstructionCostsAlreadyAvailable.YES,
    constructionEstimateRecieved: SurveyConstructionEstimateRecieved.YES,
    roughConstructionStartDate: ON_SEASON_CONSTRUCTION_TIMELINE[0],
    pax: "1 Person",
    primaryPersonNumberOfSalaries: 14,
    occupation: BorrowerEmploymentType.SELF_EMPLOYED,
    primaryPersonIncome: 4_500,
    purchaseProgress: SurveyPurchaseProgress.HELD_CONVERSATION_WITH_BANKS,
    howSoonFinancingCommitmentNeeded: ON_SEASON_CONSTRUCTION_TIMELINE[0],
    whichBanksAreHouseBanks: SurveyBankOption.ERSTE,
    houseBankFinancingPreference: SurveyHouseBankFinancingPreference.NONE,
    referral: BorrowerReferralType.GOOGLE,
    firstName: "Max",
    email: "test@miracl.at",
};
