import { User } from "firebase/auth";

export type ApplicationUser = {
    loggedIn: boolean;
    email: string;
    emailVerified: boolean;
    name: string;
    phoneNumber: string;
    imageUrl?: string;
    loggedInAt: Date;
    user: User;
};

export enum LoginStatus {
    SUCCESS = "success",
    ERROR = "error",
    UNAUTHORIZED = "unauthorized",
    LOGGED_OUT = "loggedOut",
    LOADING = "loading",
}

export enum SignupStatus {
    INITIAL = "initial",
    SUCCESS = "success",
    ERROR = "error",
}
