import { Translator } from "src/context/LocaleContext";
import { EMPTY_STRING } from "../constants/string";
import { SafeNumber } from "./format";
import { format } from "date-fns";

const TIMESTAMP_MULTIPLIER = 1000;
const MONTHS_IN_YEAR = 12;

export const getFormattedDate = (date: Date, format: "iso" | "utc" = "iso"): string => {
    if (!date || !(date instanceof Date)) return;

    switch (format) {
        case "iso":
            return date.toISOString();
        case "utc":
            return date.toUTCString();
    }
};

export const getFormattedDateFromTimestamp = (timestamp: number, format: "iso" | "utc" = "iso"): string => {
    const result = new Date(timestamp);

    return getFormattedDate(result, format);
};

export const getFormattedDateFromYear = (year: number, format: "iso" | "utc" = "iso"): string => {
    if (!year) return;

    try {
        const result = new Date();
        result.setFullYear(year);
        return getFormattedDate(result, format);
    } catch (err) {
        console.error(`An error occured while formating date from year, invalid value: ${year}`);
    }
};

export const getFormattedDateFromAge = (age: number, format: "iso" | "utc" = "iso"): string => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    return getFormattedDateFromYear(currentDate.getFullYear() - age, format);
};

export const getYearCountFromMonths = (months: number): number => {
    if (!months) return 0;
    return months / MONTHS_IN_YEAR;
};

export const getBirthYearDateFromAge = (age: number, exactBirthdate?: Date) => {
    const date = new Date();
    date.setFullYear(date.getFullYear() - age);
    if (exactBirthdate) {
        const exactBirthdayMonth = exactBirthdate.getMonth();
        const exactBirthdayDay = exactBirthdate.getDate();
        const currentDateMonth = date.getMonth();
        const currentDateDay = date.getDate();
        date.setMonth(exactBirthdayMonth);
        date.setDate(exactBirthdayDay);
        /**
         * MOR-474: Borrower age decreases by 1 on each sync
         * Reason: If today's date is before the birthday,
         * when we reduce the current year by the age, it's one year too little,
         * and in that case we need to deduct one more year in order to match the exact year
         */
        if (
            exactBirthdayMonth > currentDateMonth ||
            (exactBirthdayMonth === currentDateMonth && exactBirthdayDay > currentDateDay)
        ) {
            date.setFullYear(date.getFullYear() - 1);
        }
    }
    return date;
};

export const getDateFromTimestamp = (timestamp: number, withMultiplier = false): Date => {
    return new Date(timestamp * (withMultiplier ? TIMESTAMP_MULTIPLIER : 1));
};

export const getYearFromTimestamp = (timestamp: number): number => {
    return getDateFromTimestamp(timestamp)?.getFullYear();
};

export const getDateStringFromDate = (date: Date, translator: Translator) => {
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();

    return `${translator(`months.${month + 1}`)} ${day}, ${year}`;
};

export const getDateTimeStringFromDate = (date: Date, translator: Translator) => {
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    const formattedTime = format(date.getTime(), "HH:mm");

    return `${translator(`months.${month + 1}`)} ${day}, ${year} ${formattedTime}`;
};

export const getDateStringFromTimestamp = (
    timestamp: number,
    translator: Translator,
    defaultValue: string = EMPTY_STRING,
): string => {
    if (!timestamp) return defaultValue;

    return getDateStringFromDate(getDateFromTimestamp(timestamp), translator);
};

export const getDateTimeStringFromTimestamp = (
    timestamp: number,
    translator: Translator,
    defaultValue: string = EMPTY_STRING,
): string => {
    if (!timestamp) return defaultValue;

    return getDateTimeStringFromDate(getDateFromTimestamp(timestamp), translator);
};

/**
 * MOR - 577
 * Since our date picker passes an UTC formatted date to it's on change
 * it's unable to properly parse partial dates like "01.01.1" while the user
 * is typing and it corrupts the data mid process
 * This will be used to prevent the date picket to set the date value until it's valid
 * @param date
 * @returns
 */
export const isDateStringValid = (date: string, separator = " ", threshold = 1900): boolean => {
    if (!date) return false;

    return SafeNumber(date.split(separator)?.[3]) >= threshold;
};

export const getAgeFromBirthdate = (birthdate: number) => {
    try {
        if (!birthdate) return 0;
        return Math.abs(new Date(Date.now() - birthdate).getFullYear() - 1970);
    } catch (error) {
        return 0;
    }
};

export const getDateAfterNMonths = (months: number): Date => {
    const result = new Date();

    result.setMonth(result.getMonth() + months);

    return result;
};

/**
 *
 * @param {number} to - last year in the array
 * @returns {number[]} array of numbers from current year to year represented with parameter "to"
 */
export const getYearsBackTo = (to = 1950): number[] => {
    const result = [];
    const currentYear = new Date().getFullYear();

    for (let year = currentYear; year >= to; year--) {
        result.push(year);
    }

    return result;
};

/**
 * Returns a date timestamp with hours part set to zeros
 * @param date
 * @returns
 */
export const getTimestampWithoutTime = (date: Date): number => {
    const cleanDate = new Date(date);
    cleanDate.setHours(0, 0, 0, 0);
    return cleanDate.getTime();
};

/**
 * @description
 * Returns a new date object with time values set to zero
 *
 * @param date date to update
 */
export const getCleanDate = (date: Date): Date => {
    const cleanDate = new Date(date);
    cleanDate.setHours(0, 0, 0, 0);

    return cleanDate;
};

export const getPrevEnumeratedMonthsForTimeRange = (timeRange = 3): number[] => {
    const result = [];
    let currentMonth = new Date().getMonth();

    for (let i = timeRange; i > 0; i--) {
        if (currentMonth === 0) {
            currentMonth = 12;
        }
        result.push(currentMonth);
        currentMonth--;
    }

    return result;
};

export const getPrevEnumeratedYearsForTimeRange = (timeRange = 3): number[] => {
    const result = [];
    let currentYear = new Date().getFullYear();

    for (let i = timeRange; i > 0; i--) {
        result.push(currentYear);
        currentYear--;
    }

    return result;
};

export const addYears = (date: Date, years: number) => {
    date.setFullYear(date.getFullYear() + years);
    return date;
};

export const addDays = (date: Date, days: number) => {
    date.setDate(date.getDate() + days);
    return date;
};
