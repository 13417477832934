import { Storage } from "./types";

const isEmpty = (val: unknown): boolean => val === undefined || val === null;

export const storageService: Storage = {
    get: (key, defaultValue?) => {
        const value = localStorage.getItem(key);
        return !isEmpty(value) ? String(value) : defaultValue;
    },
    getParsed: (key, defaultValue?, parser = JSON.parse) => {
        const value = localStorage.getItem(key);

        if (isEmpty(value)) return defaultValue;
        try {
            return parser(value);
        } catch (e) {
            return defaultValue;
        }
    },

    getNumber: (key: string) => Number(localStorage.getItem(key)),
    getBoolean: (key: string) => Boolean(localStorage.getItem(key)),

    set: (key, value?) => localStorage.setItem(key, typeof value === "object" ? JSON.stringify(value) : String(value)),

    remove: (key) => localStorage.removeItem(key),
    clear: () => localStorage.clear(),
};
