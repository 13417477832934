import { config } from "src/config";
import { sentryService } from "src/service/sentry";

import { GenericEventParams } from "src/service/google/analytics";
import { trackConversionEvent } from "src/service/gtag/api";
import { sendAnalyticsEvent } from "../ga-events/api";

export const ConversionEnum = {
    INTEREST_PURCHASE: config.google.adsConversionId.interest.purchase,
    INTEREST_REFINANCING: config.google.adsConversionId.interest.refinancing,
    INTEREST_CONSTRUCTION: config.google.adsConversionId.interest.construction,
    SEGMENTED_PURCHASE: config.google.adsConversionId.segmentedPurchase,
    FINISH_PURCHASE: config.google.adsConversionId.finishPurchase,
} as const;

/**
 * Used for reporting Ads conversions and Analytics events
 */
class GTagService {
    private readonly AVG_LEAD_TO_DEAL_CONVERSION = 0.1;
    private readonly AVG_AGENCY_FEE = 0.025;
    private readonly USER_MISSING_PHONE_NUMBER = 0.05;

    // feature flags to enable events or conversions sent to GTAG specifically.
    // does not influence our custom analytics API
    private readonly GTAG_EVENTS_ENABLED = false;
    private readonly GTAG_CONVERSIONS_ENABLED = true;

    private gclid: string | null = null;
    private initializationComplete = false;

    public init(gclid: string | null) {
        this.gclid = gclid;

        if (this.initializationComplete) {
            return;
        }

        if (!window.gtag) {
            sentryService.report("[GTAG SERVICE] gtag reference not found");

            // no need to fail here, because our own conversion tracking should work without gtag too
        }

        this.initializationComplete = true;
    }

    private calculateConversionValue(mortgageAmount: number, missingPhoneNumber = false): number {
        if (mortgageAmount <= 0) {
            sentryService.report("Invalid conversion value given, verify the estimate object: " + mortgageAmount);

            return mortgageAmount;
        }

        /* mortgage amount * average lead-to deal conversion rate in HS * average commission rate. */
        let conversionValue = mortgageAmount * this.AVG_LEAD_TO_DEAL_CONVERSION * this.AVG_AGENCY_FEE;

        /* User that did not pass their phone number are less "good" */
        if (missingPhoneNumber) {
            conversionValue *= this.USER_MISSING_PHONE_NUMBER;
        }

        return conversionValue;
    }

    private offlineConversionEventTracking = async (conversionId: string, value: number) => {
        try {
            if (!this.gclid) {
                console.warn("Event aborted! Invalid gclid found to report offline conversion.");

                return;
            }

            sentryService.setContext("TrackConversionEvent", {
                gclid: this.gclid,
                conversionId,
                value,
            });

            await trackConversionEvent(this.gclid, conversionId, value);
        } catch (error) {
            sentryService.report(error);
        }
    };

    /**
     * Sends a conversion event to our backend.
     *
     * @param {number|null} conversionValue  - The value associated with the conversion, the conversion value is zero (0) if null is passed
     * @param {string} conversionId - The ID of the conversion event. Defaults to ConversionEnum.FINISH if not provided.
     * @param missingPhoneNumber - Flag indicating whether the phone number is missing for the conversion. Defaults to false.
     * if the phone number is missing, then the calculatedConversionValue is "less worth".
     *
     * @returns void
     */
    public sendConversionEvent = async (
        conversionValue: number | null,
        conversionId: string | null,
        missingPhoneNumber = false,
    ) => {
        if (!conversionId) {
            sentryService.report(`Skipping conversion due to unknown ID ${conversionId}.`);

            return;
        }

        const calculatedConversionValue =
            conversionValue === null ? 0 : this.calculateConversionValue(conversionValue, missingPhoneNumber);

        await this.offlineConversionEventTracking(conversionId, calculatedConversionValue);

        if (!this.GTAG_CONVERSIONS_ENABLED) {
            return;
        }

        if (window.gtag) {
            window.gtag("event", "conversion", {
                value: calculatedConversionValue,
                send_to: conversionId,
                currency: "EUR",
                event_callback: function () {
                    console.log(`Logged conversion for ID ${conversionId}`);
                },
            });
        } else {
            sentryService.report("window object is not available for gTag.");
        }
    };

    /**
     * Sends an event to Google Analytics via gtag with optional event parameters.
     *
     * This method is a general purpose method which automatically includes the tracking ID in the data sent.
     * If the `name` or `gtag` is not properly set up, an error is reported by sentry.
     *
     * @param {string} name The name of the event to send. This should be a unique identifier for the event.
     * @param {GenericEventParams} [data] Optional event parameters to send with the event.
     *
     * @example
     * // Send an event with additional parameters
     * sendEvent('purchase', { value: 100, currency: 'EUR', transaction_id: '12345' });
     *
     * @returns void
     */
    public sendEvent = (name: string, data?: GenericEventParams) => {
        sentryService.setContext("GTag", {
            name,
            gtag: window?.gtag,
        });

        if (!name?.length) {
            sentryService.report("Event name is invalid");

            return;
        }

        sendAnalyticsEvent({
            action: name,
            params: data,
        });

        if (!this.GTAG_EVENTS_ENABLED) {
            return;
        }

        if (window.gtag) {
            const dataWithTagId = data
                ? { ...data, send_to: config.google.trackingId }
                : { send_to: config.google.trackingId };
            const { browser, os, device, language, ...gaData } = dataWithTagId as any;

            window.gtag("event", name, gaData);
        } else {
            sentryService.report("window object is not available for gTag.");
        }
    };
}

const gTagService = new GTagService();

export default gTagService;
