export type AppSettings = {
    NEXT_DOCUMENT_ON_SAVE: boolean;
    BANK_NAME_VISIBLE: boolean;
    USE_EXACT_NUMBER_OF_BORROWERS_TEMPLATES: boolean;
    DISPLAY_SNOWFLAKES: boolean;
    DISPLAY_CHRISTMAS_LIGHTS: boolean;
};

export const DEFAULT_APP_SETTINGS: AppSettings = {
    NEXT_DOCUMENT_ON_SAVE: true,
    BANK_NAME_VISIBLE: false,
    USE_EXACT_NUMBER_OF_BORROWERS_TEMPLATES: false,
    DISPLAY_SNOWFLAKES: false,
    DISPLAY_CHRISTMAS_LIGHTS: false,
};
