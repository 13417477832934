export enum UnauthorizedMessage {
    INVALID_TOKEN = "Unauthorized! Invalid token!",
    EXPIRED_TOKEN = "Unauthorized! Expired token!",
    AUTHORIZATION_FAILED = "Unauthorized! Authorization failed!",
}

export enum SignupErrorCode {
    EMAIL_ALREADY_IN_USE = "auth/email-already-in-use",
    USER_NOT_WHITE_LISTED = "notWhitelisted",
}

export enum LoginErrorCode {
    WRONG_PASSWORD = "auth/wrong-password",
    USER_NOT_FOUND = "auth/user-not-found",
}
