import { Component, ErrorInfo, ReactNode } from "react";
import { sentryService } from "src/service/sentry";

interface RenderFallbackProps {
    message: string;
}

interface ErrorBoundaryWrapperProps {
    children: ReactNode;
    renderFallback?: ({ message }: RenderFallbackProps) => JSX.Element;
}

interface ErrorBoundaryWrapperState {
    renderError: boolean;
    message: string;
    renderFallback: ({ message }: RenderFallbackProps) => JSX.Element;
}

const RenderFallbackDefault = ({ message }: RenderFallbackProps) => {
    return <h1>{message}</h1>;
};

class ErrorBoundaryWrapper extends Component<ErrorBoundaryWrapperProps, ErrorBoundaryWrapperState> {
    constructor(props: ErrorBoundaryWrapperProps) {
        super(props);
        this.state = {
            renderError: false,
            message: "",
            renderFallback: props.renderFallback ?? RenderFallbackDefault,
        };
    }

    static getDerivedStateFromError(error: Error) {
        return { renderError: true, message: error.stack };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        sentryService.setContext("Error boundary error info", errorInfo);
        sentryService.report(error);
    }

    render() {
        const FallbackComponent = this.state.renderFallback;

        if (this.state.renderError) {
            return <FallbackComponent message={this.state.message} />;
        }

        return this.props.children;
    }
}

export default ErrorBoundaryWrapper;
