import * as Yup from "yup";
import { DealProperties } from "../../../models";
import { Shape } from "../types";
import { numberValidation } from "../util";

export const financingCostsSchema = Yup.object<Shape<Partial<DealProperties>>>({
    landRegistryEntryFee: numberValidation(0),
    landRegistryEntryFeeCosts: numberValidation(0),
    agencyFee: numberValidation(0),
    agencyCosts: numberValidation(0),
    landRegistryApplicationEntryCosts: numberValidation(0),
    notaryCosts: numberValidation(0),
    appraisalCosts: numberValidation(0),
    oneTimeAdditionalCreditCosts: numberValidation(0),
    wholeCreditTermCosts: numberValidation(0),
    grossLoanAmount: numberValidation(0),
});
