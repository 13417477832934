import { ThemeProvider } from "@mui/material";
import { ReactNode, useContext } from "react";
import { AuthContext } from "./AuthContext";
import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { generateColorScheme } from "src/utils/colors";

type UserThemeProviderProps = {
    children: ReactNode;
};

export function UserThemeProvider({ children }: UserThemeProviderProps) {
    const { profile } = useContext(AuthContext);

    const createUserTheme = () => {
        let theme = createTheme({
            palette: {
                primary: profile?.company?.colorScheme || generateColorScheme(),
            },
            typography: {
                fontFamily: [
                    "Poppins",
                    "Roboto",
                    '"Helvetica Neue"',
                    "Arial",
                    "sans-serif",
                    '"Apple Color Emoji"',
                    '"Segoe UI Emoji"',
                    '"Segoe UI Symbol"',
                ].join(","),
                fontSize: 11,
            },
            components: {
                MuiTooltip: {
                    styleOverrides: {
                        tooltip: {
                            padding: 0,
                            backgroundColor: "white",
                            color: "black",
                        },
                    },
                },
            },
        });

        // Updating the theme for the MUI Rich Text editor
        Object.assign(theme, {
            overrides: {
                MUIRichTextEditor: {
                    root: {
                        height: "100%",
                        width: "100%",
                        cursor: "text",
                        backgroundColor: "white",
                        padding: "8px",
                    },
                },
            },
        });

        return responsiveFontSizes(theme);
    };

    return <ThemeProvider theme={createUserTheme()}>{children}</ThemeProvider>;
}
