import qs from "qs";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { AVAILABLE_LANGUAGES, Language } from "../../types/language";
import { useNavigator } from "../useNavigator";

export const useLanguageFromQueryParams = (langParamName = "lang", defaultLanguage = Language.DE) => {
    const { navigate } = useNavigator();
    const [language, setLanguage] = useState<Language>(defaultLanguage);
    const location = useLocation();
    const { search } = location || {};

    useEffect(() => {
        const queryParams = qs.parse(search?.replace("?", ""));
        if (AVAILABLE_LANGUAGES.includes(queryParams?.[langParamName] as Language))
            setLanguage(queryParams?.[langParamName] as Language);
    }, [langParamName, search]);

    const onLanguageChange = (value: Language) => {
        navigate(location.pathname, { search: { [langParamName]: value }, preserveParams: true, replace: true });
    };

    return { language, onLanguageChange };
};
