export enum DealPropertyEquipmentStandardType {
    SIMPLE = "einfach",
    MEDIUM = "mittel",
    HIGH = "gehoben",
}

export enum DealPropertyModernizationDegreeType {
    NOT_MODERNIZED = "nicht modernisiert",
    AVERAGELY_MODERNIZED = "durchschnittlich modernisiert",
    COMPERHENSIVELY_MODERNIZED = "umfassend modernisiert",
}
