import { useRef, useEffect } from "react";

export const usePreventUpdateOnScroll = () => {
    const inputRef = useRef(null);

    useEffect(() => {
        inputRef.current &&
            inputRef.current.addEventListener("wheel", (e) => {
                e.target.blur();
            });
    }, [inputRef]);

    return inputRef;
};
