/**
 * Regex based on this answer: https://stackoverflow.com/a/9204568
 * enabling us to simply test if an email is of anything@anyting.anything format
 */
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const isEmailValid = (value: string): boolean => {
    return emailRegex.test(value);
};

// https://stackoverflow.com/a/21125098/198996
export function getCookie(name: string | number) {
    const match = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`));
    if (match) return match[2];
}
