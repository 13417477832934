import { CircularProgress, Grid, Typography } from "@mui/material";
import { useTranslation } from "src/context/LocaleContext";
import "./style.scss";

interface CustomerLoginPageProps {
    loginError: boolean;
}
export const CustomerLoginPage = ({ loginError }: CustomerLoginPageProps) => {
    const { t } = useTranslation();
    const loginPageText = t(`login_status_message_${!loginError ? "loading" : "error"}`);

    return (
        <Grid container width="95%" height="100vh" flexDirection="column" justifyContent="center" alignItems="center">
            <Grid container item justifyContent="center" alignItems="center" sx={{ marginBottom: "10px" }}>
                <CircularProgress color="primary" size={100} />
            </Grid>
            <Grid container item justifyContent="center" alignItems="center">
                <Typography fontSize={24} color="primary" fontWeight="bolder" textAlign="center">
                    {loginPageText}
                </Typography>
            </Grid>
        </Grid>
    );
};
