import { ReactNode, useContext } from "react";
import { LocaleContext } from "./LocaleContext";
import deLocale from "date-fns/locale/de-AT";
import enLocale from "date-fns/locale/en-GB";
import { LocalizationProvider as MuiLocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Language } from "src/shared/types/language";

type LocalizationProviderProps = {
    children: ReactNode;
};

export function LocalizationProvider({ children }: LocalizationProviderProps) {
    const { language } = useContext(LocaleContext);

    const locale = language === Language.EN ? enLocale : deLocale;

    return (
        <MuiLocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
            {children}
        </MuiLocalizationProvider>
    );
}
