import { ReactNode, createContext, useContext, useEffect } from "react";
import { useCookies } from "react-cookie";
import { addDays } from "src/shared/util/date";
import { getCookieOptions } from "src/utils/cookie";
import { generateId } from "src/utils/generateId";

type SessionContextData = {
    sessionId: string;
};

const SessionContext = createContext<SessionContextData>({
    sessionId: "",
});

type SessionProviderProps = {
    children: ReactNode;
};

export const SESSION_ID_COOKIE_KEY = "sessionId";

export function SessionProvider({ children }: SessionProviderProps) {
    const [cookies, setCookie] = useCookies([SESSION_ID_COOKIE_KEY]);

    useEffect(() => {
        if (!cookies.sessionId) {
            const id = generateId();

            setCookie(SESSION_ID_COOKIE_KEY, id, {
                ...getCookieOptions(),
                expires: addDays(new Date(), 1),
            });
        }
    }, [cookies, setCookie]);

    return (
        <SessionContext.Provider
            value={{
                sessionId: cookies.sessionId,
            }}
        >
            {children}
        </SessionContext.Provider>
    );
}

export const useSession = () => useContext(SessionContext);
