export enum Milestone {
    CATEGORIZE_ESIS = "categorize_esis",
    CATEGORIZE_MORTGAGE_OFFER = "categorize_mortgage_offer",
    CATEGORIZE_CONSULTATION_PROTOCOL = "categorize_consultation_protocol",
    CATEGORIZE_MORTGAGE_COMPARISON = "categorize_mortgage_comparison",
    CATEGORIZE_MORTGAGE_CONTRACT = "categorize_mortgage_contract",
    CATEGORIZE_CONSULTATION_AGREEMENT = "categorize_consultation_agreement",
    DOCUMENT_UPLOADED = "milestone_document_uploaded",
    PANDADOC_CREATED = "milestone_pandadoc_created",
    PANDADOC_COMPLETED = "milestone_pandadoc_completed",
    PANDADOC_SENT = "milestone_pandadoc_sent",
    CONSULTATION_STARTED = "milestone_consultation_started",
    CONSULTATION_MORTGAGE_COMPARISON = "milestone_consultation_mortgage_comparison",
    CUSTOMER_APPOINTMENT_BOOKING = "customer_appointment_booking",
    EXPORT_FIXED_PERMIT = "milestone_export_fixed_permit",
}
